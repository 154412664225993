import React, { useState, useEffect, useRef, useCallback } from 'react';
import './semanticSearch.css';
import { FaSearch, FaEye, FaExternalLinkAlt } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai';
import XLSXViewer from '../components/fileExplorer/xlsxViewer';
import { getIdToken } from '../services/userDataService';
import { useNotification } from '../components/useNotification';
import axios from 'axios';
import { ChevronDown, ChevronRight, Info } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Tooltip, TooltipContent, TooltipTrigger, TooltipProvider } from '../components/ui/tooltip';

/**
 * Highlights the search term within a given text string using the <mark> tag.
 *
 * @param {string} text - The text to highlight.
 * @param {string} searchTerm - The search term to highlight.
 * @returns {string|Array<string|JSX.Element>} The text with search term highlighted with `<mark>` tags.
 */
const highlightSearchTerms = (text, searchTerm) => {
  try {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, 'gi');
    return text.split(regex).map((part, index) =>
      regex.test(part) ? (
        <span key={index} className="highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  } catch (e) {
    console.log(e);
  }
};

/**
 * SemanticSearch Component
 *
 * Provides a semantic search interface that allows users to search within their documents.
 * It fetches and displays both document-level and chunk-level results. This component is responsible
 * for rendering search input, results, and a document viewer.
 *
 * @param {object} props - The component's props.
 * @param {object} props.userData - User data object.
 * @returns {JSX.Element} The SemanticSearch JSX element.
 */
const SemanticSearch = ({ userData }) => {
  /**
   * @type {[string, function]} query - The current search term entered by user.
   */
  const [query, setQuery] = useState('');
  /**
  * @type {[Array<object>, function]} results - An array of results returned by API, at the chunk-level granularity.
  */
  const [results, setResults] = useState([]);         // chunk-level results
  /**
  * @type {[Array<object>, function]} topDocuments - An array of results returned by API, at the document-level granularity.
  */
  const [topDocuments, setTopDocuments] = useState([]); // doc-level results
  /**
   * @type {[boolean, function]} hasSearched -  Boolean indicating if a search has been performed.
   */
  const [hasSearched, setHasSearched] = useState(false);
  /**
 * @type {[boolean, function]} isLoading - Boolean that represents the loading state when a search is performed.
 */
  const [isLoading, setIsLoading] = useState(false);
  /**
    * @type {[string|null, function]} viewerUrl - URL that is used to display the document in an iFrame.
    */
  const [viewerUrl, setViewerUrl] = useState(null);
  /**
 * @type {[string|null, function]} viewerType - Represents type of document that needs to be displayed in an iFrame.
 */
  const [viewerType, setViewerType] = useState(null);
  /**
* @type {[string|null, function]} error - Represents the error message when an error is encountered when fetching or processing documents.
*/
  const [error, setError] = useState(null);
  /**
* @type {[boolean, function]} showDocs - Boolean that controls the expand/collapse state for document results.
*/
  const [showDocs, setShowDocs] = useState(true);   // Collapsible state for doc-level
  /**
   * @type {[boolean, function]} showChunks - Boolean that controls the expand/collapse state for chunk-level results.
   */
  const [showChunks, setShowChunks] = useState(true); // Collapsible state for chunk-level

  const { addNotification } = useNotification();
  /**
   * @type {function} navigate - React router hook for navigation
   */
  const navigate = useNavigate();

  /**
 * Handles the submission of the search form. It makes a call to the backend
 * API for a semantic search, updates state with the results of the search.
 *
 * @param {object} e - The submit event object.
 */
  const handleSearch = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setHasSearched(true);

    try {
      // Make the GET request. 
      // We assume the response has shape: { doc_summaries: [...], chunks: [...] }
      console.log('Making request with query:', query);
      console.log('User data:', userData);
      const chunkResponse = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/semanticSearch?query=${query}&org_id=${userData.user_data.org_id}&user_id=${userData.user_data.user_id}&database=${userData.vector_databases[0].database_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: await getIdToken(),
          },
        }
      );

      console.log(chunkResponse.data);
      const { doc_summaries, chunks } = chunkResponse.data;

      setTopDocuments(doc_summaries || []);
      setResults(chunks || []);
    } catch (error) {
      addNotification('Error fetching search results', 'error');
      setTopDocuments([]);
      setResults([]);
    } finally {
      setIsLoading(false);
    }
  };

  /**
  * Fetches a presigned URL from backend for viewing the document.
  *
  * @param {string} url - The URL of the document to view.
  * @param {string} documentType - The MIME type of the document.
  * @param {number} page_number - Page number of the document
  */
  const callViewFunction = async (url, documentType, page_number) => {
    setError(null);
    try {
      console.log('Calling view function with:', url, documentType, page_number);
      const encodedUrl = encodeURIComponent(url);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/inClientViewer?file_url=${encodedUrl}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: await getIdToken(),
          },
        }
      );

      if (!response.ok) {
        addNotification('Error fetching document', 'error');
      }

      const parsed_data = await response.json();
      console.log('Parsed data:', parsed_data);

      if (parsed_data.presigned_url) {
        setViewerUrl(parsed_data.presigned_url + `#page=${page_number || 1}`);
        setViewerType(documentType);
      } else {
        addNotification('Error fetching document', 'error');
      }
    } catch (error) {
      addNotification('Error fetching document', 'error');
      setError(error.message);
    }
  };

  /**
    * Renders a document viewer based on document type (PDF, XLSX, etc.).
    *
    * @returns {JSX.Element} - JSX to display the document viewer.
    */
  const renderViewer = () => {
    if (error) {
      addNotification('Error fetching document', 'error');
      return;
    }
    switch (viewerType) {
      case 'xlsx':
      case 'xls':
        return <XLSXViewer fileUrl={viewerUrl} />;
      // For PDF, docx, etc., just show in an iframe
      default:
        return (
          <iframe
            src={viewerUrl}
            title="Document Viewer"
            width="100%"
            height="100%"
            frameBorder="0"
            onLoad={() => addNotification('Document loaded successfully', 'success')}
            onError={(e) => addNotification('Error loading document', 'error')}
          />
        );
    }
  };

  return (
    <div className="SemanticSearch">
      <div className="search-content">

        {/* Search Bar */}
        <div className="search-bar-container">
          <form onSubmit={handleSearch} className="semantic-search-bar">
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search your documents..."
              className="search-input"
            />
            <button
              type="submit"
              disabled={isLoading}
              className="semantic-search-button"
            >
              <FaSearch className="semantic-search-icon" />
            </button>
          </form>
        </div>

        {/* Loading spinner if needed */}
        {isLoading && (
          <div className="pulsing-dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        )}

        {/* Placeholder before first search */}
        {!hasSearched && !isLoading && (
          <div className='some-placeholder-box'>
            <div className="search-placeholder">
              <h2 className='big-search-text'>Welcome to Semantic Search</h2>
              <div className='mid-search-placeholder-text'>
                <p className='small-search-text'>Enter your query above to search through your documents.</p>
                <p className='small-search-text'>
                  Our advanced semantic search will help you find relevant information
                  quickly and efficiently.
                </p>
              </div>
            </div>
          </div>
        )}

        {/* Results Section */}
        {hasSearched && !isLoading && (
          <div className="search-results">

            {/* Similar Documents Accordion */}
            {topDocuments.length > 0 && (
              <div className="similar-docs-section">
                <div
                  className="section-header"
                  onClick={() => setShowDocs((prev) => !prev)}
                >
                  <div className='search-section-header'>Similar Documents</div>
                  <span className="accordion-arrow">
                    {showDocs ? <ChevronDown /> : <ChevronRight />}
                  </span>
                </div>
                {showDocs && (
                  <div className="docs-list">
                    {topDocuments.map((docItem, index) => {
                      const doc = docItem.document || {};
                      return (
                        <div key={index} className="search-document-row">
                          <div className="doc-info">
                            <span className="doc-icon">📄</span>
                            <span className="doc-name">{doc.file_name}</span>
                          </div>
                          <div className="doc-actions">
                            {/* <button
                              title="Search Within This Document"
                              onClick={() =>
                                doc.url
                                  ? callViewFunction(doc.url, doc.file_type, 1)
                                  : addNotification('No URL found', 'warning')
                              }
                            >
                              <FaSearch />
                            </button> */}
                            {/* External link icon for 'open in new tab' if you store presigned URLs? */}
                            <button
                              title="View Document Details"
                              onClick={() => {
                                navigate(`../document/${doc.document_id}`);
                              }}
                            >
                              <FaExternalLinkAlt />
                            </button>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}

            {/* Similar Chunks Accordion */}
            {results.length > 0 && (
              <div className="similar-chunks-section">
                <div
                  className="section-header"
                  onClick={() => setShowChunks((prev) => !prev)}
                >
                  <div className='search-section-header flex items-center'>Similar Chunks
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Info className="text-black bg-transparent ml-4" style={{ width: "1rem" }} />
                        </TooltipTrigger>
                        <TooltipContent className="text-xs font-normal">
                          <p className="font-normal">A chunk is a short sub-section of a document,</p> 
                            <p>generally about a paragraph in length</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>

                  <span className="accordion-arrow">
                    {showChunks ? <ChevronDown /> : <ChevronRight />}
                  </span>
                </div>
                {showChunks && (
                  <div className="chunks-list">
                    {results.map((chunkItem, index) => {
                      const chunkDoc = chunkItem.document || {};
                      return (
                        <div key={index} className="chunk-row">
                          <div className="chunk-header">
                            <div className='chunk-source'>
                              <strong>Source:</strong> {chunkDoc.file_name || 'Unknown'}
                            </div>
                            {/* If you have creation date or uploadedBy, show it */}
                            <span className='chunk-meta'>
                              <strong> Created On:</strong>
                              {chunkDoc.upload_date || 'N/A'}
                            </span>
                            {/* <span className='chunk-meta'>
                              <strong> Uploaded By:</strong> Joe Smith 
                            </span> */}
                          </div>
                          <div className='chunk-details'>
                            <div className="chunk-content">
                              <p>
                                <strong>Content:</strong> {chunkDoc.content}
                              </p>
                            </div>

                            <div className="chunk-actions">
                              <button
                                onClick={() =>
                                  chunkDoc.url
                                    ? callViewFunction(
                                      chunkDoc.url,
                                      chunkDoc.file_type,
                                      chunkDoc.page_number
                                    )
                                    : addNotification('No URL found', 'warning')
                                }
                                className='chunk-action-button'>
                                View Source
                              </button>
                              {/* <button
                                onClick={() =>
                                  addNotification('Find Similar not implemented yet', 'info')
                                }
                                className='chunk-action-button' >
                                Find Similar
                              </button> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}

            {/* If both doc_summaries and chunks are empty */}
            {topDocuments.length === 0 && results.length === 0 && (
              <div className="no-results">No results found. Try a different search term.</div>
            )}
          </div>
        )}
      </div>

      {/* Document Viewer (Fullscreen) */}
      {viewerUrl && (
        <div className="fullscreen-viewer">
          <div className="viewer-header">
            <button
              className="close-button"
              onClick={() => {
                setViewerUrl(null);
                setViewerType(null);
                setError(null);
              }}
            >
              <AiOutlineClose />
            </button>
          </div>
          {renderViewer()}
        </div>
      )}
    </div>
  );
};

export default SemanticSearch;
