// src/components/fileExplorer/VersionConflictModal.js

import React from 'react';
import './VersionConflictModal.css'; // Create appropriate styles
import { AiOutlineClose } from 'react-icons/ai';

/**
 * VersionConflictModal Component
 *
 * Provides a modal to handle version conflicts when a user attempts to upload
 * a file with the same name as an existing file. It allows the user to choose to
 * add a new version, replace the current version, or cancel the upload.
 *
 * @param {object} props - The component's props.
 * @param {boolean} props.isOpen - Controls whether the modal is open.
 * @param {function} props.onClose - Callback function to close the modal.
 * @param {function} props.onAddNewVersion - Callback function to handle adding a new version of the file.
 * @param {function} props.onReplaceVersion - Callback function to handle replacing the current version of the file.
 * @returns {JSX.Element|null} The VersionConflictModal JSX element, or null if the modal is closed.
 */
const VersionConflictModal = ({ isOpen, onClose, onAddNewVersion, onReplaceVersion }) => {
   // Render null if the modal is closed
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" role="dialog" aria-modal="true" aria-labelledby="version-conflict-title">
      <div className="modal-content">
        <button className="modal-close-button" onClick={onClose} aria-label="Close Modal">
          <AiOutlineClose />
        </button>
        <h2 id="version-conflict-title">Document Already Exists</h2>
        <p>This document already exists in your organization's database.</p>
        <div className="modal-buttons">
          <button onClick={onAddNewVersion} className="modal-button add-version-button">
            Add New Version
          </button>
          {/* <button onClick={onReplaceVersion} className="modal-button replace-version-button">
            Replace Current Version
          </button> */}
          <button onClick={onClose} className="modal-button cancel-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default VersionConflictModal;
