import React from 'react';

const ScoreHalfMoon = ({ percentage, label, size, stroke_width }) => {
    const radius = size;
    const normalizedPercentage = Math.max(0, Math.min(100, percentage));
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (normalizedPercentage / 100) * circumference;

    const strokeColor = percentage > 75 ? '#4ade80' : (percentage > 50 ? '#facc15' : '#f87171');


    return (
        <div className="flex flex-col items-center">
            <svg width={radius * 2 + 8} height={radius * 2 + 8} viewBox={`-4 -4 ${radius * 2 + 16} ${radius * 2 + 16}`}> {/* Increased viewBox and added negative origin */}
                <circle
                    cx={radius + 4}     // Adjusted cx and cy to center within larger viewBox
                    cy={radius + 4}
                    r={radius}
                    fill="transparent"
                    stroke="#e2e8f0"
                    strokeWidth={stroke_width}
                />
                <circle
                    cx={radius + 4}     // Adjusted cx and cy to center within larger viewBox
                    cy={radius + 4}
                    r={radius}
                    fill="transparent"
                    stroke={strokeColor}
                    strokeWidth={stroke_width}
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                    strokeLinecap="round"
                    transform={`rotate(-90 ${radius + 4} ${radius + 4})`} // Adjusted rotation origin
                />
                <text
                    x={radius + 4}     // Adjusted x and y to center text in larger circle area
                    y={radius + 4}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    className="text-xl font-bold"
                >
                    {normalizedPercentage}%
                </text>
            </svg>
            <div className="text-sm text-muted-foreground mt-1">{label}</div>
        </div>
    );
};

export default ScoreHalfMoon;