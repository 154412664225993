import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IoMdClose } from 'react-icons/io';
import { debounce } from 'lodash';
import './sharePopup.css';
import { getIdToken } from '../../services/userDataService';

/**
 * SharePopup Component
 *
 * Provides a modal for sharing a document with other users.
 * Allows users to search for users, select multiple users,
 * and then shares the document using a callback.
 *
 * @param {object} props - The component's props.
 * @param {boolean} props.isOpen - Controls whether the modal is open.
 * @param {function} props.onClose - Callback function to close the modal.
 * @param {object} props.documentToShare - The document object to be shared.
 * @param {function} props.onShare - Callback function to share the document with selected users.
 * @param {object} props.userData - User data object.
 * @returns {JSX.Element|null} The SharePopup JSX element, or null if the modal is closed.
 */
const SharePopup = ({ isOpen, onClose, documentToShare, onShare, userData }) => {
     /**
     * @type {[string, function]} searchTerm - The current search term entered by the user.
     */
    const [searchTerm, setSearchTerm] = useState('');
      /**
     * @type {[Array<object>, function]} users - An array of all available user objects.
     */
    const [users, setUsers] = useState([]);
      /**
     * @type {[Array<object>, function]} filteredUsers - An array of filtered user objects based on search term.
     */
    const [filteredUsers, setFilteredUsers] = useState([]);
     /**
     * @type {[Array<object>, function]} selectedUsers - An array of user objects that are currently selected for sharing.
     */
    const [selectedUsers, setSelectedUsers] = useState([]);
      /**
     * @type {[boolean, function]} isLoading - Represents if the users are loading.
     */
    const [isLoading, setIsLoading] = useState(false);
      /**
     * @type {React.MutableRefObject<null>} searchRef - A ref that points to the search input element.
     */
    const searchRef = useRef(null);
     /**
     * @type {Set<string>} selectedUsersSet - A set of user IDs for quick lookups.
     */
    const selectedUsersSet = new Set(selectedUsers.map(u => u.user_id));

    /**
     * Fetches all users from the backend.
     * Sets the `isLoading` state while fetching, and clears it when done.
     * Updates the `users` state with the fetched data or logs an error in case of failure.
     */
    const fetchUsers = async () => {
        setIsLoading(true);
        try {
            const fetchUsersURL = process.env.REACT_APP_FETCH_USERS_URL;
            const response = await fetch(`${fetchUsersURL}?org_id=${userData.user_data.org_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': await getIdToken()
                }
            });
            const data = await response.json();
            console.log('Fetched users:', data);
            if (data.users) {
                setUsers(data.users);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setIsLoading(false);
        }
    };


    /**
     * Loads users on modal open and clears search state.
     */
    useEffect(() => {
        if (isOpen) {
            fetchUsers();
            setSearchTerm('');
            setFilteredUsers([]);
            setSelectedUsers([]);
        }
    }, [isOpen]);

    /**
     * Debounced search function that filters users based on the search term.
     *
     * @type {function} debouncedSearch -  A debounced function that filters users based on the search term.
     */
    const debouncedSearch = useCallback(
        debounce((value) => {
            console.log('Searching for:', value);
            if (value.trim()) {
                const filtered = users.filter(user =>
                    user.name.toLowerCase().includes(value.toLowerCase()) ||
                    user.email.toLowerCase().includes(value.toLowerCase())
                );
                console.log('Filtered users:', filtered);
                setFilteredUsers(filtered);
            } else {
                setFilteredUsers([]);
            }
        }, 300),
        [users]
    );

     /**
     * Sets the filtered users based on search term.
     */
    useEffect(() => {
        debouncedSearch(searchTerm);
    }, [searchTerm, debouncedSearch]);

    /**
     * Sets the search term whenever the value of the search input changes.
     *
     * @param {object} e - The change event object.
     */
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
    };

    /**
     * Handles user selection or deselection based on user clicks.
     * Updates the array of `selectedUsers`
     *
     * @param {object} user - The user object that was selected/deselected.
     */
    const handleUserSelect = (user) => {
        console.log('Selecting/Deselecting user:', user);
        setSelectedUsers(prev => {
            const newSelection = selectedUsersSet.has(user.user_id)
                ? prev.filter(u => u.user_id !== user.user_id)
                : [...prev, user];
            console.log('New selection:', newSelection);
            return newSelection;
        });
        searchRef.current?.focus();
    };

    console.log('Current selected users:', selectedUsers);

    // Render null if the modal is closed
    if (!isOpen) return null;

    return (
        <div className="share-popup-overlay" onClick={onClose}>
            <div className="share-popup" onClick={(e) => e.stopPropagation()}>
                <div className="share-popup-header">
                    <h2>Share "{documentToShare.document_name}"</h2>
                    <button className="share-popup-close" onClick={onClose}><IoMdClose /></button>
                </div>
                <div className="share-popup-content">
                    <div className="share-search-container">
                        <input
                            ref={searchRef}
                            type="text"
                            placeholder="Search users..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="share-search-input"
                        />
                        {isLoading && <div className="share-loading">Loading...</div>}
                        {filteredUsers.length > 0 && (
                            <ul className="share-autocomplete-dropdown">
                                {filteredUsers.map(user => (
                                    <li
                                        key={user.user_id}
                                        onClick={() => handleUserSelect(user)}
                                        className="share-autocomplete-item"
                                    >
                                        <input
                                            type="checkbox"
                                            checked={selectedUsersSet.has(user.user_id)}
                                            readOnly
                                        />
                                        {user.name} ({user.email})
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <div className="share-selected-users">
                        {selectedUsers.map(user => (
                            <div key={user.user_id} className="share-selected-user">
                                {user.name}
                                <button onClick={() => handleUserSelect(user)} className="share-remove-user">×</button>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="share-popup-footer">
                    <button
                        className="share-popup-button share-clear"
                        onClick={() => setSelectedUsers([])}
                    >
                        Clear
                    </button>
                    <button
                        className="share-popup-button share-submit"
                        onClick={() => {
                            onShare(documentToShare, selectedUsers);
                            onClose();
                        }}
                        disabled={selectedUsers.length === 0}
                    >
                        Share
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SharePopup;
