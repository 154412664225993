// src/pages/admin.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import './admin.css';
import { UserPlus, Users, FileText, ChevronLeft, ChevronRight } from 'lucide-react';
import { useNotification } from '../components/useNotification';
import LoadingSpinner from '../components/assets/loadingSpinner';
import UserCreationModal from '../components/userCreationModal';
import ManageUserGroupsModal from '../components/manageUserGroupsModal';
import { getIdToken } from '../services/userDataService';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/card";

// Recharts imports
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  AreaChart, Area
} from 'recharts';

/**
 * AdminPage Component
 *
 * Provides a dashboard for administrators to manage organization users, user
 * groups, and view usage metrics. This component fetches and displays user data,
 * handles user and group management, and includes charts to visualize usage trends.
 *
 * @param {object} props - The component's props.
 * @param {object} props.userData - User data object.
 * @returns {JSX.Element} The AdminPage JSX element.
 */
const AdminPage = ({ userData }) => {
    /**
      * @type {[boolean, function]} isLoading - Represents the loading state of dashboard data.
      */
  const [isLoading, setIsLoading] = useState(true);
    /**
     * @type {[object, function]} orgData - An object that represents the organization data (name, users, licenses, etc.).
     */
  const [orgData, setOrgData] = useState({
    org_name: '',
    address: '',
    user_count: 0,
    available_licenses: 0,
    document_count: 0,
    users: [],
  });
   /**
      * @type {[Array<object>, function]} userGroups - An array of objects that represents the user groups in this organization.
      */
  const [userGroups, setUserGroups] = useState([]);
   /**
      * @type {[boolean, function]} isUserModalOpen - Represents the open/close state of the User Creation modal.
      */
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    /**
      * @type {[boolean, function]} isGroupModalOpen - Represents the open/close state of the User Group Management modal.
      */
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
    /**
    * @type {[Array<object>, function]} metricsData - An array of objects representing time-series metrics.
    */
  const [metricsData, setMetricsData] = useState([]); // NEW: store time-series here
  /**
   * @type {React.MutableRefObject<HTMLDivElement>} scrollContainerRef - A reference to the scroll container element for the metrics charts.
   */
  const scrollContainerRef = useRef(null);

  const { addNotification } = useNotification();

  /**
    * Scroll the metrics container to the left, so that the previous metrics can be seen.
    */
  const handleScrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -scrollContainerRef.current.offsetWidth,
        behavior: 'smooth'
      });
    }
  };

    /**
    * Scroll the metrics container to the right, so that the following metrics can be seen.
    */
  const handleScrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: scrollContainerRef.current.offsetWidth,
        behavior: 'smooth'
      });
    }
  };


    /**
   * Fetches user data from the backend API, updates the orgData and loading states.
   */
  const fetchUsers = useCallback(async () => {
    console.log('Fetching users for Admin Dashboard');
    const token = await getIdToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/admin-dashboard?org_id=${userData.user_data.org_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const usersData = await response.json();
      setOrgData(usersData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      addNotification('Failed to fetch users', 'error');
      setIsLoading(false);
    }
  }, [userData.user_data.org_id, addNotification]);


    /**
   * Fetches all user groups from the backend API, updates the userGroups state.
   */
  const fetchUserGroups = useCallback(async () => {
    console.log('Fetching user groups');
    const token = await getIdToken();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/fetchUserGroups?org_id=${userData.user_data.org_id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch user groups');
      }
      const groupsData = await response.json();
      setUserGroups(groupsData);
    } catch (error) {
      console.error('Error fetching user groups:', error);
      addNotification('Failed to fetch user groups', 'error');
    }
  }, [userData.user_data.org_id, addNotification]);

    /**
     * Fetches time-series metrics from the backend API, sets the metrics data in component state.
     */
  const fetchAggregatedMetrics = useCallback(async () => {
    const token = await getIdToken();
    console.log('Fetching time-series metrics for Admin Dashboard');
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/admin/metrics` +
        `?org_id=${userData.user_data.org_id}` +
        `&start_date=2025-01-01` +  // or dynamically pick your date range
        `&end_date=2025-01-31` +
        `&grouping=day`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch metrics');
      }
      const result = await response.json();
      console.log('Aggregated metrics:', result.data);

      // Recharts typically wants an array of objects.
      // If your "bucket_start" or "snapshot_time" is in result.data,
      // rename it to something consistent, like "time".
      // Convert date strings to a more readable format if desired.
      const formattedData = (result.data || []).map((entry) => {
        // entry = { bucket_start, active_users, total_documents, storage_used_gb, queries_count }
        return {
          time: new Date(entry.snapshot_time).toLocaleTimeString(),  // or new Date(entry.bucket_start).toLocaleDateString()
          active_users: entry.active_users,
          total_documents: entry.total_documents,
          queries_count: entry.queries_count,
          storage_used_gb: entry.storage_used_gb,
        };
      });

      setMetricsData(formattedData);
    } catch (error) {
      console.error('Error fetching aggregated metrics:', error);
      addNotification('Failed to fetch metrics', 'error');
    }
  }, [userData.user_data.org_id, addNotification]);

   /**
     *  On mount, fetch user information, user groups and aggregated metrics data from backend API endpoints.
     */
  useEffect(() => {
    fetchUsers();
    fetchUserGroups();
    fetchAggregatedMetrics();
  }, [fetchUsers, fetchUserGroups, fetchAggregatedMetrics]);

   /**
     * Handler function that adds a user to a specific user group.
     * Notifies users using the notification system about the status of this operation
     * @param {string} groupId - The id of the user group.
     * @param {string} userId - The id of the user that needs to be added to the group.
     */
  const handleAddUserToGroup = useCallback(
    async (groupId, userId) => {
      const token = await getIdToken();
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/add-user-to-group`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            org_id: userData.user_data.org_id,
            group_id: groupId,
            user_id: userId,
            admin_user_id: userData.user_data.user_id,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          addNotification('User added to group successfully', 'success');
          fetchUserGroups();
        } else {
          addNotification(`Failed to add user to group: ${data.error || 'Unknown error'}`, 'error');
        }
      } catch (error) {
        console.error('Error adding user to group:', error);
        addNotification('Error adding user to group: ' + error.message, 'error');
      }
    },
    [userData.user_data.org_id, userData.user_data.user_id, addNotification, fetchUserGroups]
  );

    /**
     * Handler function that creates a user group.
     * Notifies users using the notification system about the status of this operation
     * @param {string} name - Name of the user group that needs to be created.
     * @param {string} description - Description of the user group that needs to be created.
     */
  const handleAddGroup = useCallback(
    async (name, description) => {
      const token = await getIdToken();
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/add-user-group`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            org_id: userData.user_data.org_id,
            name,
            description,
            admin_user_id: userData.user_data.user_id,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          addNotification('User group added successfully', 'success');
          fetchUserGroups();
        } else {
          addNotification(`Failed to add user group: ${data.error || 'Unknown error'}`, 'error');
        }
      } catch (error) {
        console.error('Error adding user group:', error);
        addNotification('Error adding user group: ' + error.message, 'error');
      }
    },
    [userData.user_data.org_id, userData.user_data.user_id, addNotification, fetchUserGroups]
  );

    /**
   * Handler function that creates a new user, and uses the notification system to alert users about the process.
   * @param {object} newUser - The object that represents the data of the new user.
   */
  const handleAddUser = useCallback(
    async (newUser) => {
      const token = await getIdToken();
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/userProvisioner`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${token}`,
          },
          body: JSON.stringify({
            org_id: userData.user_data.org_id,
            email: newUser.email,
            name: newUser.name,
            username: newUser.username,
            user_group: newUser.userGroup,
            admin_user_id: userData.user_data.user_id,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          addNotification('User added successfully', 'success');
          fetchUsers();
        } else {
          addNotification(`Failed to Add User: ${data.error || 'Unknown error'}`, 'error');
        }
      } catch (error) {
        console.error('Error adding user:', error);
        addNotification('Error adding user: ' + error.message, 'error');
      }
    },
    [userData.user_data.org_id, userData.user_data.user_id, addNotification, fetchUsers]
  );

  return (
    <div className="admin-page">
      <div className="admin-content">
        {isLoading ? (
          <div className="loading-spinner-container">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="admin-header">
              <div className="admin-title-section">
                <h1 className="admin-title">Administrator Dashboard</h1>
                <div className="admin-org-info">
                  <h2>{orgData.org_name}</h2>
                  <p>{orgData.address}</p>
                </div>
              </div>
              <div className="admin-metrics">
                <div className="metric-card">
                  <UserPlus size={24} />
                  <span className="metric-value">{orgData.user_count}</span>
                  <span className="metric-label">Named Users</span>
                </div>
                <div className="metric-card">
                  <Users size={24} />
                  <span className="metric-value">{orgData.available_licenses}</span>
                  <span className="metric-label">Available Seats</span>
                </div>
                <div className="metric-card">
                  <FileText size={24} />
                  <span className="metric-value">{orgData.document_count}</span>
                  <span className="metric-label">Uploaded Files</span>
                </div>
              </div>
            </div>

            <div className="admin-section">
              <div className="section-header">
                <h2 className="section-title">User Management</h2>
                <div className="admin-actions">
                  <button onClick={() => setIsUserModalOpen(true)} className="action-button">
                    <UserPlus size={18} />
                    Add User
                  </button>
                  <button onClick={() => setIsGroupModalOpen(true)} className="action-button">
                    <Users size={18} />
                    Manage Groups
                  </button>
                </div>
              </div>
              <div className="admin-table">
                <div className="table-header">
                  <div>Name</div>
                  <div>Email</div>
                  <div>Roles</div>
                  <div>Status</div>
                </div>
                <div className="table-body">
                  {orgData.users.map((user) => (
                    <div className="table-row" key={user.user_id}>
                      <div>{user.name}</div>
                      <div>{user.email}</div>
                      <div>{user.groups}</div>
                      <div>
                        <span className="status-badge active">Active</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Charts Section */}
            <div
              className="charts-section mt-4 relative"
              // Add margin-left if your sidebar is e.g. 64px wide, so that the left button doesn't overlap
            >
              <h2 className="section-title mb-4">Usage Metrics</h2>

              {/* 
                Left scroll button: 
                Place it inside this relative container so it’s positioned after the sidebar.
              */}
              <button
                onClick={handleScrollLeft}
                className="absolute left-2 top-1/2 -translate-y-1/2 z-10 bg-white rounded-full border border-gray-300 p-2 shadow"
              >
                <ChevronLeft size={20} />
              </button>

              {/* Right scroll button */}
              <button
                onClick={handleScrollRight}
                className="absolute right-2 top-1/2 -translate-y-1/2 z-10 bg-white rounded-full border border-gray-300 p-2 shadow"
              >
                <ChevronRight size={20} />
              </button>

              {/* Scrollable container */}
              <div
                className="w-full overflow-x-hidden"
                style={{ scrollBehavior: 'smooth' }}
                ref={scrollContainerRef}
              >
                {/* 
                  Child wide enough to overflow horizontally. 
                  If you want 50% / 50%, use minWidth: '200%'.
                  If you want 75% / 25%, pick accordingly, etc.
                */}
                <div className="flex" style={{ minWidth: '200%' }}>

                  {/* 3-charts group (occupies ~50% of the width) */}
                  <div className="flex w-1/2 gap-4">

                    {/* Each card has a fixed height so Recharts can display */}
                    <Card className="w-1/3 h-[300px] p-0">
                      <CardContent className="h-full">
                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart data={metricsData} margin={{ top: 20, right: 35 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="basis"
                              dataKey="active_users"
                              strokeWidth={2}
                              stroke="#8884d8"
                              name="Active Users"
                              dot={false}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>

                    <Card className="w-1/3 h-[300px] p-0">
                      <CardContent className="h-full">
                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart data={metricsData} margin={{ top: 20, right: 35 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="basis"
                              dataKey="total_documents"
                              strokeWidth={2}
                              stroke="#82ca9d"
                              name="Total Docs"
                              dot={false}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>

                    <Card className="w-1/3 h-[300px] p-0">
                      <CardContent className="h-full">
                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart data={metricsData} margin={{ top: 20, right: 35 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line
                              type="basis"
                              dataKey="queries_count"
                              strokeWidth={2}
                              stroke="#ff7300"
                              name="Queries"
                              dot={false}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      </CardContent>
                    </Card>
                  </div>

                  {/* Storage chart (occupies ~50% of the width) */}
                  <Card className="w-1/2 h-[300px] p-0">
                    <CardContent className="h-full">
                      <ResponsiveContainer width="100%" height="100%">
                        <AreaChart data={metricsData} margin={{ top: 30, right: 30, left: 0, bottom: 0 }}>
                          <defs>
                            <linearGradient id="colorStorage" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                            </linearGradient>
                          </defs>
                          <XAxis dataKey="time" />
                          <YAxis />
                          <CartesianGrid strokeDasharray="3 3" />
                          <Tooltip />
                          <Legend />
                          <Area
                            type="monotone"
                            dataKey="storage_used_gb"
                            stroke="#8884d8"
                            fillOpacity={1}
                            fill="url(#colorStorage)"
                            name="Storage (GB)"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    </CardContent>
                  </Card>

                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Modals */}
      <UserCreationModal
        isOpen={isUserModalOpen}
        onClose={() => setIsUserModalOpen(false)}
        onSubmit={handleAddUser}
        userGroups={userGroups}
      />
      <ManageUserGroupsModal
        isOpen={isGroupModalOpen}
        onClose={() => setIsGroupModalOpen(false)}
        userGroups={userGroups}
        allUsers={orgData.users}
        onAddGroup={handleAddGroup}
        onFetchGroups={fetchUserGroups}
        onAddUserToGroup={handleAddUserToGroup}
      />
    </div >
  );
};

export default AdminPage;
