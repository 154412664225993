import { useState } from 'react';
import process from 'process';
import { getIdToken } from '../services/userDataService';
import { useNotification } from '../components/useNotification';

/**
 * Custom hook for deleting data from the backend.
 *
 * @function useDeleteData
 * @returns {object} An object containing the `deleteData` function, `isDeleting` state, and `error` state.
 */
export const useDeleteData = () => {
    const [isDeleting, setIsDeleting] = useState(false);
    const [error, setError] = useState(null);

    const {addNotification} = useNotification();

    const DELETE_API = process.env.REACT_APP_DELETE_API;

    /**
     * Deletes data of a specified type for a given user and organization.
     *
     * @async
     * @function deleteData
     * @param {string} userId - The ID of the user.
     * @param {string} orgId - The ID of the organization.
     * @param {string} deleteType - The type of data to delete (e.g., 'document', 'project', 'chat', 'tag', 'folder').
     * @param {string} [itemId=null] - The ID of the item to delete (if applicable).
     * @param {string} vector_db - The ID of the vector database.
     * @returns {Promise<boolean>}  `true` if deletion was successful, `false` otherwise.
     * @throws {Error} Throws an error if the deletion request fails.
     */
    const deleteData = async (userId, orgId, deleteType, itemId = null, vector_db) => {
        setIsDeleting(true);
        setError(null);
        console.log('Deleting data', userId, orgId, deleteType, itemId);
        try {
            const idToken = await getIdToken();
            const response = await fetch(DELETE_API, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': idToken,
                },
                body: JSON.stringify({
                    'user_id': userId,
                    'org_id': orgId,
                    'delete_type': deleteType,
                    'item_id': itemId,
                    'vector_db': vector_db,
                    'delete_shared': deleteType === 'chat'
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to delete data');
            }

            addNotification('Data deleted successfully', 'success');
            return true;
        } catch (err) {
            addNotification('Failed to delete data', 'error');
            return false;
        } finally {
            setIsDeleting(false);
        }
    };

    return { deleteData, isDeleting, error };
};
