import React, { useEffect, useState } from 'react';
import { getIdToken } from '../../services/userDataService'; // Adjust path if needed
import { useNotification } from '../../components/useNotification'; // Adjust path if needed

// Shadcn UI Components
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import { Card, CardHeader, CardTitle, CardDescription, CardContent } from "../../components/ui/card";
import { Progress } from "../../components/ui/progress"; // Optional: for loading indicator

const JobOpeningUploadForm = ({ userData }) => {
    const [jobTitle, setJobTitle] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const { addNotification } = useNotification();

    const handleTitleChange = (e) => {
        setJobTitle(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setJobDescription(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const token = await getIdToken(); // Get token for authorization
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/recruit-ai/ingestJobOpening`, { // **REPLACE WITH YOUR ACTUAL API GATEWAY URL**
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token, // If authorization is needed
                },
                body: JSON.stringify({
                    organizationId: userData.user_data.org_id, // Assuming org_id is available in userData
                    jobTitle: jobTitle,
                    jobDescription: jobDescription,
                    database: userData.vector_databases[0].database_id, // Add any other job details you want to send in the future
                    // Add any other job details you want to send in the future
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to ingest job opening. Status: ${response.status}`);
            }

            const data = await response.json();
            console.log('Job opening ingested:', data);
            addNotification('Job opening uploaded successfully!', 'success');
            setJobTitle('');
            setJobDescription(''); // Clear form on success

        } catch (error) {
            console.error('Error ingesting job opening:', error);
            addNotification(`Error uploading job opening: ${error.message}`, 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleClear = () => {
        setJobTitle('');
        setJobDescription('');
    };

    return (
        <Card className='max-h-2/3'>
            <CardHeader>
                <CardTitle>Upload Job Opening</CardTitle>
                <CardDescription>Enter the details of the job opening to add it to the Job Bank.</CardDescription>
            </CardHeader>
            <CardContent>
                {loading && (
                    <div className="relative pb-2 mb-4"> {/* Added mb-4 for spacing below progress */}
                        <Progress value={100} className="absolute top-0 left-0 w-full" />
                        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-sm">
                            Uploading Job Opening...
                        </div>
                    </div>
                )}
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <Input
                            type="text"
                            placeholder="Job Title"
                            value={jobTitle}
                            onChange={handleTitleChange}
                            id="job-title"
                            className="rounded-xl"
                        />
                    </div>
                    <div className='h-full'>
                        <Textarea
                            placeholder="Job Description"
                            value={jobDescription}
                            onChange={handleDescriptionChange}
                            rows={15}
                            id="job-description"
                            className="rounded-xl h-full"
                        />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <Button type="submit" loading={loading} size="sm" className="rounded-xl">Upload Job</Button>
                        <Button type="button" variant="secondary" onClick={handleClear} disabled={loading} size="sm" className="rounded-xl">Clear</Button>
                    </div>
                </form>
            </CardContent>
        </Card>
    );
};

export default JobOpeningUploadForm;