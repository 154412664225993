import React, { useState, useEffect } from 'react';
import { useParams,Link, useNavigate  } from 'react-router-dom';
import { getIdToken } from '../../services/userDataService';
import './CandidateProfile.css'; // Keep your custom CSS for any specific overrides

// Shadcn UI Components (Import the ones you are using)
import { Card, CardHeader, CardTitle, CardContent } from "../../components/ui/card" // Assuming your Shadcn components are in "@/components/ui/" - adjust path if needed
import { Skeleton } from "../../components/ui/skeleton"
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert"
import { Badge } from "../../components/ui/badge" // For skills, could be nice
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion" // Import Accordion components
import { ChevronDown } from "lucide-react"; // Import ChevronDown icon from lucide-react (or react-icons)
import { Button } from "../../components/ui/button" // Import Button for back button
import { ArrowLeft } from "lucide-react"; // Import ArrowLeft icon

// import { List, ListItem } from "../../components/ui/list" // Or use styled <ul> and <li>


const CandidateProfile = (userData) => {
    const { applicantId } = useParams();
    const [candidate, setCandidate] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Initialize useNavigate for programmatic navigation

    useEffect(() => {
        const fetchCandidateDetails = async () => {
            setLoading(true);
            setError(null);
            const token = await getIdToken();

            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/fetchCandidate?applicantId=${applicantId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': token,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setCandidate(data);
                } else {
                    setError('Failed to fetch candidate details.');
                }
            } catch (err) {
                setError('Error fetching candidate details.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchCandidateDetails();
    }, [applicantId]);

    if (loading) {
        return (
            <Card className="candidate-profile p-4 space-y-4 w-full max-w-5xl mx-auto"> {/* Encapsulated in Card, added max-w and mx-auto */}
                <Skeleton className="h-10 w-full" />
                <div className="md:grid md:grid-cols-2 md:gap-6">
                    <div>
                        <Skeleton className="h-4 w-3/4 mb-2" />
                        <Skeleton className="h-4 w-1/2 mb-2" />
                        <Skeleton className="h-20 w-full" />
                    </div>
                    <div>
                        <Skeleton className="h-40 w-full mb-2" />
                        <Skeleton className="h-30 w-full" />
                    </div>
                </div>
            </Card>
        );
    }

    if (error) {
        return (
            <Card className="w-full p-4 max-w-5xl mx-auto"> {/* Encapsulated in Card, added max-w and mx-auto */}
                <Alert variant="destructive">
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            </Card>
        );
    }

    if (!candidate) {
        return <Card className="w-full p-4 max-w-5xl mx-auto">Candidate not found.</Card>; {/* Encapsulated in Card, added max-w and mx-auto */}
    }

    // Helper function to render a section as an Accordion item
    const renderSection = (title, content) => {
        if (!content) return null;

        return (
            <AccordionItem value={title.toLowerCase().replace(/\s+/g, '-')}>
                <AccordionTrigger className="hover:bg-gray-100 rounded-md transition-colors duration-200 p-4 cursor-pointer rounded-2xl flex items-center justify-between">
                    <h3 className="text-lg font-semibold tracking-tight mt-4">{title}</h3>
                    {/* <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50 transition-transform duration-200 peer-data-[state=open]:rotate-180" /> */}
                </AccordionTrigger>
                <AccordionContent className="pb-4 overflow-y-auto max-h-96">
                    {content}
                </AccordionContent>
            </AccordionItem>
        );
    };

    // Function to capitalize first letter of each word
    const capitalizeFirstLetterOfWords = (name) => {
        if (!name) return '';
        return name.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    };

    return (
        <Card className="candidate-profile p-6 space-y-4 w-full max-w-7xl mx-auto"> {/* Encapsulated in Card, added max-w and mx-auto */}
            <CardHeader className="pb-2"> {/* Added CardHeader for potential title area */}
                <CardTitle className="flex items-center justify-between"> {/* Added CardTitle for title and back button */}
                    <Button variant="ghost" size="sm" className="mr-2 bg-black text-white rounded-xl" onClick={() => navigate('/recruit-ai')}> {/* Back Button */}
                        <ArrowLeft className="h-4 w-4 mr-2" /> Back
                    </Button>
                    <h2 className="text-3xl font-bold tracking-tight m-0">{capitalizeFirstLetterOfWords(candidate.parsedData.name)}</h2> {/* Capitalized Name */}
                </CardTitle>
            </CardHeader>
            <CardContent> {/* CardContent for the main profile content */}
                <Accordion type="single" collapsible>
                    <div className="md:grid md:grid-cols-2 md:gap-6">
                        <div className="space-y-2">
                            {renderSection("Personal Summary", <p className="text-muted-foreground">{candidate.parsedData.personal_summary}</p>)}

                            {renderSection("Contact Information", (
                                <p className="text-muted-foreground break-words">
                                    {candidate.parsedData.contact_info.split('|').map((item, index) => (
                                        <span key={index}>{item.trim()}<br /></span>
                                    ))}
                                </p>
                            ))}

                            {renderSection("Skills", (
                                <div className="flex flex-wrap gap-2">
                                    {candidate.parsedData.skills.map((skill, index) => (
                                        <Badge key={index} variant="secondary">{skill}</Badge>
                                    ))}
                                </div>
                            ))}
                        </div>

                        <div className="space-y-2">
                            {renderSection("Work Experience", (
                                <div className="space-y-2">
                                    {candidate.parsedData.work_experience.map((job, index) => (
                                        <div key={index} className="job mb-3">
                                            <h4 className="text-lg font-semibold">{job.position} at {job.company}</h4>
                                            <p className="text-sm text-muted-foreground">{job.years}</p>
                                            <p className="text-muted-foreground">{job.description}</p>
                                        </div>
                                    ))}
                                </div>
                            ))}

                            {renderSection("Education", (
                                <div className="space-y-2">
                                    {candidate.parsedData.education.map((edu, index) => (
                                        <div key={index} className="education mb-3">
                                            <h4 className="text-lg font-semibold">{edu.degree} from {edu.institution}</h4>
                                            <p className="text-sm text-muted-foreground">{edu.year}</p>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </Accordion>
            </CardContent>
        </Card>
    );
};

export default CandidateProfile;