// hooks/useUsers.js
import { useState, useEffect } from 'react';
import { getIdToken } from '../services/userDataService';
import process from 'process';

/**
 * Custom hook to fetch and manage the list of users within an organization.
 *
 * @function useUsers
 * @param {string} orgId - The ID of the organization for which to fetch users.
 * @returns {object} An object containing the fetched users, loading state, and error state.
 * @property {object[]} users - An array of user objects.
 * @property {boolean} loading - A boolean indicating whether the data is currently being fetched.
 * @property {Error|null} error - An error object if an error occurred during the fetch, otherwise null.
 */
export const useUsers = (orgId) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    /**
     * Fetches the list of users for the given organization ID.
     * 
     * @async
     * @function fetchUsers
     * @returns {Promise<void>}
     * @throws {Error} Throws an error if there's an issue fetching users.
     */
    const fetchUsers = async () => {
      if (!orgId) return;
      const idToken = await getIdToken();
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/fetchUsers?org_id=${orgId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': idToken,
            }
        });
        const users_object = await response.json();
        console.log('users_object', users_object);
        setUsers(users_object.users);
      } catch (err) {
        console.error('Error fetching users:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [orgId]);

  return { users, loading, error };
};
