import React, { useState } from 'react';
import './MoveModal.css'; // Create a CSS file as needed

/**
 * MoveModal Component
 *
 * Provides a modal for selecting a target folder when moving files within
 * a file explorer.  It displays a hierarchical list of folders, allowing users
 * to choose the target location.
 *
 * @param {object} props - The component's props.
 * @param {boolean} props.isOpen - Controls whether the modal is open.
 * @param {function} props.onClose - Callback function to close the modal.
 * @param {Array<object>} props.folders - An array of folder objects with `{id, name, parent_folder_id}` properties.
 * @param {function} props.onMove - Callback function to move the files when a folder is selected.
 * @returns {JSX.Element|null} The MoveModal JSX element, or null if the modal is closed.
 */
const MoveModal = ({ isOpen, onClose, folders, onMove }) => {
    /**
    * @type {[string|null, function]} selectedFolder - The currently selected folder ID or null if root is selected.
    */
    const [selectedFolder, setSelectedFolder] = useState(null);

    // Build a folder tree or at least show a list of folders
    // Assuming `folders` is a flat array with {id, name, parent_id}
    // We'll create a recursive component to display hierarchy.
    console.log(folders);

    /**
     * Renders a hierarchical folder tree using recursion.
     *
     * @param {string|null} parentId - The ID of the parent folder to filter folders for.  Defaults to null.
     * @param {number} level - The current indentation level, initially set to 0 when rendering the top-level folder
     * @returns {JSX.Element} The JSX structure for the folder tree.
     */
    const renderFolderTree = (parentId = null, level = 0) => {
        return folders
            .filter(folder => folder.parent_folder_id === parentId)
            .map(folder => (
                <div key={folder.id} style={{ marginLeft: level * 20 }}>
                    <label>
                        <input
                            type="radio"
                            name="targetFolder"
                            value={folder.id}
                            checked={selectedFolder === folder.id}
                            onChange={() => setSelectedFolder(folder.id)}
                        />
                        {folder.name}
                    </label>
                    {renderFolderTree(folder.id, level + 1)}
                </div>
            ));
    };

    return (
        isOpen && (
            <div className="modal-overlay">
                <div className="modal">
                    <h2>Select a Folder to Move Files Into</h2>
                    <div className="folder-list">
                        {/* If you want a "root" option: */}
                        <label>
                            <input
                                type="radio"
                                name="targetFolder"
                                value=""
                                checked={selectedFolder === null}
                                onChange={() => setSelectedFolder(null)}
                            />
                            Root
                        </label>
                        {renderFolderTree(null, 1)}
                    </div>
                    <div className="modal-buttons">
                        <button
                            onClick={() => {
                                onMove(selectedFolder);
                            }}
                            disabled={selectedFolder === null} // or allow null if root is allowed
                        >
                            Move Here
                        </button>
                        <button onClick={onClose}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    );
};

export default MoveModal;
