import React from 'react';

/**
 * CustomHamburgerIcon Component
 *
 * Renders a custom hamburger icon using SVG.
 * This component is used to represent the menu toggle for the sidebar.
 * The icon consists of three horizontal bars with rounded corners.
 * It also has a drop shadow filter.
 *
 * @returns {JSX.Element} The CustomHamburgerIcon JSX.
 */
const CustomHamburgerIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="10"
      y="20"
      width="80"
      height="10"
      rx="2"
      fill="#00000"
      style={{ filter: 'drop-shadow(0px 0 5px rgba(0, 0, 0, 0.3))' }}
    />
    <rect
      x="10"
      y="45"
      width="80"
      height="10"
      rx="2"
      fill="#00000"
      style={{ filter: 'drop-shadow(0px 0 5px rgba(0, 0, 0, 0.3))' }}
    />
    <rect
      x="10"
      y="70"
      width="80"
      height="10"
      rx="2"
      fill="#00000"
      style={{ filter: 'drop-shadow(0px 0 5px rgba(0, 0, 0, 0.3))' }}
    />
  </svg>
);

export default CustomHamburgerIcon;
