import React, { useState, useEffect } from 'react';
import { FaBars, FaBell, FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import ProfileModal from './ProfileModal';
import './topbar.css';

/**
 * Defines the API endpoint for fetching notifications.
 *
 * @type {string}
 */
const NOTIFICATIONS_API_URL = process.env.REACT_APP_BASE_URL + '/notifications';

/**
 * Asynchronously fetches the last 5 notifications for a user.
 *
 * @param {string} userId - The user ID to fetch notifications for.
 * @returns {Promise<Array<object>>} A promise that resolves to an array of notifications.
 * @throws {Error} If the fetch operation fails.
 */
async function fetchLast5Notifications(userId) {
    const payload = {
        action: 'get',
        data: {
            user_id: userId
        }
    };

    const response = await fetch(NOTIFICATIONS_API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    });

    if (!response.ok) {
        throw new Error(`Error fetching notifications: ${response.statusText}`);
    }

    const responseBody = await response.json();
    // Depending on your integration, you might need to do:
    // const parsedBody = JSON.parse(responseBody.body);
    // const allNotifications = parsedBody.notifications || [];

    const allNotifications = responseBody.notifications || [];
    return allNotifications.slice(0, 5); // keep only 5
}


/**
 * TopBar Component
 *
 * Provides a top navigation bar for the application. It includes a menu toggle button,
 * a search bar, notifications display, and user profile information.
 *
 * @param {object} props - The component's props.
 * @param {function} props.toggleSidebar - Function to toggle the sidebar.
 * @param {string} props.userDisplayName - User display name to show in top bar.
 * @param {string} props.companyName - The name of the company.
 * @param {object} props.userData - The data of the current user.
 * @returns {JSX.Element} The TopBar JSX element.
 */
const TopBar = ({ toggleSidebar, userDisplayName, companyName, userData }) => {
   /**
     * @type {function} navigate - React router hook for navigation.
     */
    const navigate = useNavigate();
   /**
      * @type {[boolean, function]} showNotifications - Boolean to represent the open state of the notifications dropdown.
      */
    const [showNotifications, setShowNotifications] = useState(false);
       /**
      * @type {[Array<object>, function]} notifications - Array of notification objects.
      */
    const [notifications, setNotifications] = useState([]);
     /**
      * @type {[boolean, function]} showProfileModal - Boolean to represent the open state of the profile modal.
      */
    const [showProfileModal, setShowProfileModal] = useState(false);

    /**
      * Handles the click of the notification icon. It toggles the notification dropdown open, and calls fetchLast5Notifications function.
      */
    const handleNotificationsClick = async () => {
        setShowNotifications(prev => !prev);

        try {
            const data = await fetchLast5Notifications(userData.user_data.user_id);
            setNotifications(data);
        } catch (error) {
            console.error('Failed to fetch notifications', error);
        }
    };

   /**
   * Opens the user profile modal.
   */
    const handleProfileClick = () => {
        setShowProfileModal(true);
    };

    /**
     * Closes the user profile modal.
     */
    const closeProfileModal = () => {
        setShowProfileModal(false);
    };

     /**
      * Handles navigation to the Settings page.
      */
    const handleSettingsClick = () => {
        navigate('/settings');
    };

    return (
        <header className="topbar">
            <div className="topbar-left">
                <FaBars onClick={toggleSidebar} className="topbar-menu-icon" />
            </div>

            <div className="topbar-center">
                <div className="topbar-search">
                    <FaSearch className="topbar-search-icon" />
                    <input type="text" placeholder="Search..." className="topbar-search-input" />
                </div>
            </div>

            <div className="topbar-right">
                <div className="topbar-icon-wrapper" onClick={handleNotificationsClick}>
                    <FaBell className="topbar-icon" />
                    <div className={`notifications-dropdown ${showNotifications ? 'open' : ''}`}>
                        <h4>Notifications</h4>
                        <ul>
                            {notifications.map(n => (
                                <li key={n.notification_id}>{n.message}</li>
                            ))}
                        </ul>
                        <div className="view-more">
                            <a href="/notifications">View More</a>
                        </div>
                    </div>
                </div>

                <div className="topbar-user" onClick={handleProfileClick}>
                    <div className="topbar-user-avatar">
                        {userDisplayName.charAt(0).toUpperCase()}
                    </div>
                    <span className="topbar-user-name">{userDisplayName}</span>
                </div>

                <div className="topbar-settings-icon" onClick={handleSettingsClick}>
                    ⚙️
                </div>
            </div>

            {showProfileModal && <ProfileModal userData={userData} onClose={closeProfileModal} />}
        </header>
    );
};

export default TopBar;
