import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import { Badge } from "../../components/ui/badge";
import { X, Calendar as CalendarIcon } from "lucide-react";
import { Calendar } from "../../components/ui/calendar"
import { Popover, PopoverTrigger, PopoverContent } from "../../components/ui/popover"
import { cn } from "../../lib/utils"
import axios from 'axios';
import { use } from 'react';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion"

const CandidateUploadForm = ({ userData }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [personalSummary, setPersonalSummary] = useState('');

    const [workExperience, setWorkExperience] = useState([]);
    const [draftWorkExperience, setDraftWorkExperience] = useState({ position: '', company: '', yearsFrom: '', yearsTo: '', description: '' });
    const [showWorkExperienceSection, setShowWorkExperienceSection] = useState(false);

    const [education, setEducation] = useState([]);
    const [draftEducation, setDraftEducation] = useState({ degree: '', institution: '', yearFrom: '', yearTo: '' });
    const [showEducationSection, setShowEducationSection] = useState(false);

    const [skills, setSkills] = useState([]);
    const [draftSkill, setDraftSkill] = useState('');
    const [showSkillsSection, setShowSkillsSection] = useState(false);

    const [validationError, setValidationError] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [profileId, setProfileId] = useState(null);
    const [showSearchResultsSection, setShowSearchResultsSection] = useState(false); // NEW STATE VARIABLE

    const pollingInterval = 2000;
    const maxPollingAttempts = 30;
    const [pollingAttempts, setPollingAttempts] = useState(0);


    const handleAddWorkExperienceSection = () => setShowWorkExperienceSection(true);
    const handleCancelWorkExperienceSection = () => {
        setShowWorkExperienceSection(false);
        setDraftWorkExperience({ position: '', company: '', yearsFrom: '', yearsTo: '', description: '' });
    };
    const handleConfirmWorkExperienceEntry = () => {
        if (!draftWorkExperience.position || !draftWorkExperience.company || !draftWorkExperience.yearsFrom || !draftWorkExperience.yearsTo) {
            setValidationError("Please fill in Position, Company, and Date Range for Work Experience.");
            return;
        }
        setWorkExperience(prev => [...prev, draftWorkExperience]);
        setDraftWorkExperience({ position: '', company: '', yearsFrom: '', yearsTo: '', description: '' });
        setShowWorkExperienceSection(false);
        setValidationError('');
    };
    const handleDraftWorkExperienceChange = (field, value) => setDraftWorkExperience(prev => ({ ...prev, [field]: value }));
    const handleRemoveWorkExperienceEntry = (indexToRemove) => setWorkExperience(prev => prev.filter((_, index) => index !== indexToRemove));

    const handleAddEducationSection = () => setShowEducationSection(true);
    const handleCancelEducationSection = () => {
        setShowEducationSection(false);
        setDraftEducation({ degree: '', institution: '', yearFrom: '', yearTo: '' });
    };
    const handleConfirmEducationEntry = () => {
        if (!draftEducation.degree || !draftEducation.institution || !draftEducation.yearFrom || !draftEducation.yearTo) {
            setValidationError("Please fill in Degree, Institution, and Year Range for Education.");
            return;
        }
        setEducation(prev => [...prev, draftEducation]);
        setDraftEducation({ degree: '', institution: '', yearFrom: '', yearTo: '' });
        setShowEducationSection(false);
        setValidationError('');
    };
    const handleDraftEducationChange = (field, value) => setDraftEducation(prev => ({ ...prev, [field]: value }));
    const handleRemoveEducationEntry = (indexToRemove) => setEducation(prev => prev.filter((_, index) => index !== indexToRemove));

    const handleAddSkillsSection = () => setShowSkillsSection(true);
    const handleCancelSkillsSection = () => {
        setShowSkillsSection(false);
        setDraftSkill('');
    };
    const handleConfirmSkillEntry = () => {
        if (!draftSkill.trim()) {
            setValidationError("Skill cannot be empty.");
            return;
        }
        setSkills(prev => [...prev, draftSkill.trim()]);
        setDraftSkill('');
        setShowSkillsSection(false);
        setValidationError('');
    };
    const handleDraftSkillChange = (e) => setDraftSkill(e.target.value);
    const handleRemoveSkillEntry = (indexToRemove) => setSkills(prev => prev.filter((_, index) => index !== indexToRemove));


    const fetchSearchResults = async (currentProfileId) => {
        setIsSearching(true);

        if (pollingAttempts >= maxPollingAttempts) {
            setIsSearching(false);
            setValidationError("Search timed out. Please try again.");
            return;
        }

        try {
            const resultsResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/recruit-ai/getTalentSearchResults?profileId=${currentProfileId}&orgId=${userData.user_data.org_id}`);
            if (resultsResponse.status === 200) {
                setSearchResults(resultsResponse.data.top_jobs);
                setIsSearching(false);
                setPollingAttempts(0);
                setShowSearchResultsSection(true); // SHOW RESULTS SECTION ONCE RESULTS ARE BACK
                console.log('Search Results:', resultsResponse.data);
            } else if (resultsResponse.status === 202) {
                setPollingAttempts(prevAttempts => prevAttempts + 1);
                setTimeout(() => fetchSearchResults(currentProfileId), pollingInterval);
            } else if (resultsResponse.status === 404) {
                setPollingAttempts(prevAttempts => prevAttempts + 1);
                setTimeout(() => fetchSearchResults(currentProfileId), pollingInterval);
            }

        } catch (error) {
            console.error('Error fetching search results:', error);
            setIsSearching(false);
            setValidationError('Failed to retrieve search results. Please try again.');
        }
    };


    const handleStartSearch = async () => {
        if (!firstName || !lastName || !personalSummary) {
            setValidationError("Please fill in First Name, Last Name, and Personal Summary.");
            return;
        }
        setValidationError('');
        setSearchResults(null);
        setIsSearching(true);
        setShowSearchResultsSection(false); // HIDE RESULTS SECTION WHEN NEW SEARCH STARTS

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/recruit-ai/ingestTalentProfile`, {
                firstName,
                lastName,
                personalSummary,
                workExperience,
                education,
                skills,
                database: userData.vector_databases[0].database_id,
                orgId: userData.user_data.org_id,
            });
            console.log('Response from Ingestion Lambda:', response.data);
            setProfileId(response.data.profileId);
            setTimeout(() => {
                fetchSearchResults(response.data.profileId);
            }, 2000);

        } catch (error) {
            console.error('Error submitting data to Lambda:', error);
            setIsSearching(false);
            setValidationError('Failed to start search. Please try again.');
        }
    };


    const handleReset = () => {
        setFirstName(''); setLastName(''); setPersonalSummary('');
        setWorkExperience([]); setEducation([]); setSkills([]);
        setShowWorkExperienceSection(false); setShowEducationSection(false); setShowSkillsSection(false);
        setValidationError('');
        setSearchResults(null);
        setIsSearching(false);
        setProfileId(null);
        setPollingAttempts(0);
        setShowSearchResultsSection(false); // HIDE RESULTS ON RESET
    };


    return (
        <div className="flex flex-col h-full">
            <Card className="h-[75vh] max-h-[75vh] flex flex-col">
                <CardHeader>
                    <CardTitle>Candidate Details</CardTitle>
                </CardHeader>
                <CardContent className="space-y-4 h-full flex-1 flex flex-col justify-between overflow-y-auto">
                    {/* Conditionally render either the form or the results card */}
                    {!showSearchResultsSection ? (
                        <div className="space-y-4"> {/* Wrap form elements in a div */}
                            {validationError && <p className="text-red-500 text-sm">{validationError}</p>}
                            <div className="grid grid-cols-2 gap-4">
                                {/* ... (First Name, Last Name Inputs - same as before) ... */}
                                <div>
                                    <Input className='rounded-xl' type="text" placeholder="First Name" required value={firstName} onChange={e => setFirstName(e.target.value)} />
                                </div>
                                <div>
                                    <Input className='rounded-xl' type="text" placeholder="Last Name" required value={lastName} onChange={e => setLastName(e.target.value)} />
                                </div>
                            </div>
                            <div>
                                <Textarea className='rounded-xl' placeholder="Personal Summary" required rows={4} value={personalSummary} onChange={e => setPersonalSummary(e.target.value)} />
                            </div>

                            {/* ... (Work Experience, Education, Skills sections - same as before) ... */}
                             <div className="space-y-2">
                                <h4 className="font-semibold flex justify-between items-center">
                                    Work Experience
                                    {showWorkExperienceSection && (
                                        <div>
                                            <Button variant="ghost" size="sm" onClick={handleConfirmWorkExperienceEntry}>Add</Button>
                                            <Button variant="ghost" size="sm" onClick={handleCancelWorkExperienceSection}>Cancel</Button>
                                        </div>
                                    )}
                                </h4>
                                {workExperience.map((exp, index) => (
                                    <div key={index} className="p-2 py-1 border rounded-md bg-white flex justify-between items-center">
                                        <div>
                                            <div className="font-semibold">{exp.position}</div>
                                            <div className="text-sm text-muted-foreground">{exp.company} ({exp.yearsFrom} - {exp.yearsTo})</div>
                                        </div>
                                        <Button variant="ghost" size="icon" className="rounded-full" onClick={() => handleRemoveWorkExperienceEntry(index)}>
                                            <X className="h-4 w-4" />
                                        </Button>
                                    </div>
                                ))}
                                {showWorkExperienceSection && (
                                    <div className="space-y-1  p-3 rounded-md">
                                        <Input className='rounded-xl' type="text" placeholder="Position" required value={draftWorkExperience.position} onChange={(e) => handleDraftWorkExperienceChange('position', e.target.value)} />
                                        <Input type="text" placeholder="Company" required className="mt-2 rounded-xl" value={draftWorkExperience.company} onChange={(e) => handleDraftWorkExperienceChange('company', e.target.value)} />
                                        <div className="flex space-x-2 mt-2">
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant={"outline"} size="sm" className={cn("w-[130px] justify-start rounded-xl text-left font-normal", !draftWorkExperience.yearsFrom && "text-muted-foreground")}>
                                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                                        {draftWorkExperience.yearsFrom || <span>Start Date</span>}
                                                    </Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar mode="single" selected={draftWorkExperience.yearsFrom ? new Date(draftWorkExperience.yearsFrom) : undefined} onSelect={(date) => handleDraftWorkExperienceChange('yearsFrom', date ? date.toLocaleDateString('en-CA', { year: 'numeric', day: 'numeric', month: 'short', year: 'numeric' }) : '')} initialFocus />
                                                </PopoverContent>
                                            </Popover>

                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant={"outline"} size="sm" className={cn("w-[130px] justify-start rounded-xl text-left font-normal", !draftWorkExperience.yearsTo && "text-muted-foreground")}>
                                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                                        {draftWorkExperience.yearsTo || <span>End Date </span>}
                                                    </Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar mode="single" selected={draftWorkExperience.yearsTo ? new Date(draftWorkExperience.yearsTo) : undefined} onSelect={(date) => handleDraftWorkExperienceChange('yearsTo', date ? date.toLocaleDateString('en-CA', { year: 'numeric', day: 'numeric', month: 'short', year: 'numeric' }) : '')} initialFocus />
                                                </PopoverContent>
                                            </Popover>
                                        </div>
                                        <Textarea placeholder="Description" rows={4} className="mt-2 rounded-xl" value={draftWorkExperience.description} onChange={(e) => handleDraftWorkExperienceChange('description', e.target.value)} />
                                    </div>
                                )}
                                {!showWorkExperienceSection && (
                                    <Button className='rounded-xl' variant="outline" size="sm" onClick={handleAddWorkExperienceSection}>+ Add New Work Experience</Button>
                                )}
                            </div>


                            {/* Education Section */}
                            <div className="space-y-2">
                                <h4 className="font-semibold flex justify-between items-center">
                                    Education
                                    {showEducationSection && (
                                        <div>
                                            <Button variant="ghost" size="sm" onClick={handleConfirmEducationEntry}>Add</Button>
                                            <Button variant="ghost" size="sm" onClick={handleCancelEducationSection}>Cancel</Button>
                                        </div>
                                    )}
                                </h4>
                                {education.map((eduItem, index) => (
                                    <div key={index} className="p-2 py-1 border rounded-md bg-white flex justify-between items-center">
                                        <div>
                                            <div className="font-semibold">{eduItem.degree}</div>
                                            <div className="text-sm text-muted-foreground">{eduItem.institution} ({eduItem.yearFrom} - {eduItem.yearTo})</div>
                                        </div>
                                        <Button variant="ghost" size="icon" className="rounded-full" onClick={() => handleRemoveEducationEntry(index)}>
                                            <X className="h-4 w-4" />
                                        </Button>
                                    </div>
                                ))}

                                {showEducationSection && (
                                    <div className="space-y-1 p-3 rounded-md">
                                        <Input className='rounded-xl' type="text" placeholder="Degree" required value={draftEducation.degree} onChange={(e) => handleDraftEducationChange('degree', e.target.value)} />
                                        <Input type="text" placeholder="Institution" required className="mt-2 rounded-xl" value={draftEducation.institution} onChange={(e) => handleDraftEducationChange('institution', e.target.value)} />
                                        <div className="flex space-x-2 mt-2">
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant={"outline"} size="sm" className={cn("w-[130px] justify-start rounded-xl text-left font-normal", !draftEducation.yearFrom && "text-muted-foreground")}>
                                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                                        {draftEducation.yearFrom || <span>Start Year </span>}
                                                    </Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar mode="single" selected={draftEducation.yearFrom ? new Date(draftEducation.yearFrom) : undefined} onSelect={(date) => handleDraftEducationChange('yearFrom', date ? date.toLocaleDateString('en-CA', { year: 'numeric', day: 'numeric', month: 'short', year: 'numeric' }) : '')} initialFocus />
                                                </PopoverContent>
                                            </Popover>

                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant={"outline"} size="sm" className={cn("w-[130px] justify-start rounded-xl text-left font-normal", !draftEducation.yearTo && "text-muted-foreground")}>
                                                        <CalendarIcon className="mr-2 h-4 w-4" />
                                                        {draftEducation.yearTo || <span>End Year </span>}
                                                    </Button>
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-0">
                                                    <Calendar mode="single" selected={draftEducation.yearTo ? new Date(draftEducation.yearTo) : undefined} onSelect={(date) => handleDraftEducationChange('yearTo', date ? date.toLocaleDateString('en-CA', { year: 'numeric', day: 'numeric', month: 'short', year: 'numeric' }) : '')} initialFocus />
                                                </PopoverContent>
                                            </Popover>
                                        </div>
                                    </div>
                                )}
                                {!showEducationSection && (
                                    <Button className='rounded-xl' variant="outline" size="sm" onClick={handleAddEducationSection}>+ Add New Education</Button>
                                )}
                            </div>


                            {/* Skills Section */}
                            <div className="space-y-2">
                                <h4 className="font-semibold flex justify-between items-center">Skills</h4>
                                <div className="flex flex-wrap gap-2">
                                    {skills.map((skill, index) => (
                                        <Badge key={index} variant="secondary" className="rounded-full px-2 py-1">
                                            {skill}
                                            <Button variant="ghost" size="icon" className="ml-1 -mr-1 rounded-full" onClick={() => handleRemoveSkillEntry(index)}>
                                                <X className="h-3 w-3" />
                                            </Button>
                                        </Badge>
                                    ))}
                                </div>
                                {showSkillsSection && (
                                    <div className="flex flex-wrap gap-2">
                                        <Input
                                            type="text"
                                            placeholder="Skill " required
                                            className="w-auto flex-1 min-w-[100px] rounded-xl"
                                            value={draftSkill}
                                            onChange={handleDraftSkillChange}
                                            onKeyDown={(e) => { if (e.key === 'Enter') { e.preventDefault(); handleConfirmSkillEntry(); } }}
                                        />
                                    </div>
                                )}
                                {!showSkillsSection && (
                                    <Button className='rounded-xl' variant="outline" size="sm" onClick={handleAddSkillsSection}>+ Add New Skill</Button>
                                )}
                            </div>
                        </div>
                    ) : (
                        /* Render Search Results Card if showSearchResultsSection is true */
                        searchResults && searchResults.length > 0 && (
                            <Card className="mt-4">
                                <CardHeader>
                                    <CardTitle>Top Job Matches</CardTitle>
                                </CardHeader>
                                <CardContent>
                                    <Accordion type="single" collapsible>
                                        {searchResults.map((job, index) => (
                                            <AccordionItem key={index} value={`job-${index}`}>
                                                <AccordionTrigger>
                                                    <h2>{job.jobTitle}</h2>
                                                </AccordionTrigger>
                                                <AccordionContent>
                                                    <div className="p-0">
                                                        {/* <p className="text-sm text-muted-foreground">
                                                            Combined Score: {Number(job.combinedScore)}
                                                        </p> */}
                                                        <h3 className='mt-0'>Job Description</h3>
                                                        <p className="text-muted-foreground">{job.jobDescription}</p>
                                                        <h3 className='mt-4'>Required Skills</h3>
                                                        <ul className="list-disc pl-5">
                                                            {job.mandatorySkills.map((skill, index) => (
                                                                <li key={index}>{skill}</li>
                                                            ))}
                                                        </ul>
                                                        <h3>Preferred Skills</h3>
                                                        <ul className="list-disc pl-5">
                                                            {job.preferredSkills.map((skill, index) => (
                                                                <li key={index}>{skill}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                </AccordionContent>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>
                                </CardContent>
                            </Card>
                        )
                    )}


                </CardContent>
                <CardFooter>
                    <div className="w-full mt-0 flex justify-between sticky bottom-0 bg-card pt-4">
                        <Button className='rounded-2xl' onClick={handleStartSearch} disabled={!firstName || !lastName || !personalSummary || isSearching} loading={isSearching}>
                            {isSearching ? "Searching..." : "Start Search"}
                        </Button>
                        <Button className='rounded-2xl' variant="secondary" onClick={handleReset} disabled={isSearching}>Reset</Button>
                    </div>
                </CardFooter>
            </Card>

            {/* No Matches Card - Rendered outside conditional Card, always potentially visible */}
            {searchResults && searchResults.length === 0 && !isSearching && showSearchResultsSection && (
                <Card className="mt-4">
                    <CardHeader>
                        <CardTitle>No Job Matches Found</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <p>No jobs matched the candidate profile. Please try again later or modify the profile.</p>
                    </CardContent>
                </Card>
            )}
        </div>

    );
};

export default CandidateUploadForm;