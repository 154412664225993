import React, { useState } from 'react';
import { getIdToken } from '../../services/userDataService';
import { useNotification } from '../../components/useNotification';
import './jobDescriptionUpload.css'; // You might remove this later
import { Info } from "lucide-react"; // Import Info icon from lucide-react (or react-icons)

// Shadcn UI Components
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import { Progress } from "../../components/ui/progress";
import { Badge } from "../../components/ui/badge"; // For badges in results (optional)
import { Switch } from "../../components/ui/switch" // Import Switch
import { Tooltip, TooltipTrigger, TooltipContent } from "../../components/ui/tooltip" // Import Tooltip components

import ScoreHalfMoon from './ScoreHalfMoon';


const JobDescriptionUpload = ({ userData }) => {
    const [jobTitle, setJobTitle] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [isDescriptionSubmitted, setIsDescriptionSubmitted] = useState(true);
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isLinkedInSearchEnabled, setIsLinkedInSearchEnabled] = useState(false); // State for LinkedIn Search Toggle
    const { addNotification } = useNotification();

    const handleTitleChange = (e) => {
        setJobTitle(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setJobDescription(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!jobTitle || !jobDescription) {
            addNotification('Please provide a job title and description.', 'error');
            return;
        }

        console.log(userData);

        setLoading(true);
        if (isLinkedInSearchEnabled) {
            try {
                const token = await getIdToken();

                const extractResponse = await fetch(
                    'https://jbuyfsfyd0.execute-api.ca-central-1.amazonaws.com/default/ingestJobDescriptionSearch', // Replace with your actual endpoint
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            job_description: jobDescription,
                            job_title: jobTitle,
                            database: userData.vector_databases[0].database_id,
                            linkedin_search: isLinkedInSearchEnabled // Send LinkedIn search toggle state
                        }),
                    }
                );

                if (!extractResponse.ok) {
                    throw new Error('Failed to extract job details');
                }

                const extractedJobDetails = await extractResponse.json();

                const searchResponse = await fetch(
                    'https://jbuyfsfyd0.execute-api.ca-central-1.amazonaws.com/default/AIAPISearch', // Replace with your actual endpoint
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(extractedJobDetails),
                    });

                if (!searchResponse.ok) {
                    throw new Error('Failed to search for candidates');
                }

                const candidateProfiles = await searchResponse.json();

                setResults(candidateProfiles.candidate_profiles);
                setIsDescriptionSubmitted(false);
                addNotification('Job description processed and candidates found!', 'success');
            } catch (error) {
                console.error('Error submitting job description:', error);
                addNotification(`Error submitting job description: ${error.message}`, 'error');
            } finally {
                setLoading(false);
            }
        } else {
            try {
                setIsDescriptionSubmitted(false);
                const token = await getIdToken();

                const response = await fetch(`https://gurxcdfqo2er6ichkqsalwpowa0bvuoi.lambda-url.ca-central-1.on.aws/`, { 
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        organizationId: userData.user_data.org_id, // Assuming org_id is available in userData
                        jobTitle: jobTitle,
                        jobDescription: jobDescription,
                        database: userData.vector_databases[0].database_id
                    }),
                });

                console.log('response:', response);

                if (!response.ok) {
                    throw new Error(`Failed to ingest job opening. Status: ${response.status}`);
                }


                const data = await response.json();
                console.log('Job opening ingested:', data);
                setResults(data); // Clear results
                addNotification('Job description uploaded successfully!', 'success');
                setJobTitle('');
                setJobDescription(''); // Clear form on success

            } catch (error) {
                console.error('Error ingesting job opening:', error);
                addNotification(`Error uploading job description: ${error.message}`, 'error');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleReset = () => {
        setJobTitle('');
        setJobDescription('');
        setResults(null);
        setIsDescriptionSubmitted(true); // Reset to input form view
    };

    const renderResults = () => {
        if (!results) return null;

        return (
            <div className='space-y-2 overflow-y-scroll max-h-[55vh]'> {/* Added max-h-96 for scrollable results */}
                <div className="text-lg font-semibold">Search Results for Job Title: {results.job_title}</div>
                {results.top_candidates.map((item, index) => (
                    <div key={index} className="p-4 bg-card rounded-xl shadow-sm"> {/* Replaced Card with div and card-like styling */}
                        <div className="font-semibold text-lg">Name: {item.resume.parsedData.name}</div> {/* Replaced CardTitle */}
                        <div className="text-muted-foreground">Last Position: {item.resume.parsedData.work_experience[0].position}</div> {/* Replaced CardDescription */}
                        <div className="mt-4 space-y-2"> {/* Replaced CardContent and adjusted spacing */}
                            <div className="flex flex-col space-y-2 w-full items-center">
                                {/* <div className="text-xl text-muted-foreground ">Overall Score</div>
                                <div className="font-semibold text-4xl">{item.combined_score}</div> Location is used as score placeholder in original code */}
                                <ScoreHalfMoon percentage={item.combined_score} label="Overall Score" size={50} stroke_width={12}/> {/* Added ScoreHalfMoon component */}
                            </div>
                            <div className="flex space-x-4">
                                <div className="secondary-score">
                                <ScoreHalfMoon percentage={item.experience_score} label="Experience Score" size={40} stroke_width={8}/> {/* Added ScoreHalfMoon component */}

                                </div>
                                <div className="secondary-score">
                                <ScoreHalfMoon percentage={item.personal_score} label="Personal Score" size={40} stroke_width={8}/> {/* Added ScoreHalfMoon component */}
                                </div>
                                <div className="secondary-score">
                                <ScoreHalfMoon percentage={item.education_score} label="Education Score" size={40} stroke_width={8}/> {/* Added ScoreHalfMoon component */}
                                </div>
                            </div>
                            <div className="reasoning-header font-semibold">Reasoning</div>
                            <p><strong className="font-semibold">Personal:</strong> {item.personal_reasoning}</p>
                            <p><strong className="font-semibold">Education:</strong> {item.education_reasoning}</p>
                            <p><strong className="font-semibold">Experience:</strong> {item.experience_reasoning}</p>
                            {item.member_skills_collection && item.member_skills_collection.length > 0 && (
                                <div>
                                    <div className="font-semibold">Skills:</div>
                                    <ul className="list-disc list-inside pl-4">
                                        {item.member_skills_collection.map((skill, idx) => (
                                            <li key={idx}>
                                                {skill.member_skill_list.skill}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        );
    };


    const renderForm = () => (
        <div className='space-y-4'>
            <div className="space-y-2 pb-2"> {/* Replaced CardHeader with div and spacing */}
                <div className="text-lg font-semibold">Job Description Upload</div> {/* Replaced CardTitle */}
                <div className="text-muted-foreground">Enter the job title and description to find matching candidates.</div> {/* Replaced CardDescription */}
            </div>
            <div> {/* Replaced CardContent with div */}
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <Input
                            type="text"
                            placeholder="Job Title"
                            value={jobTitle}
                            onChange={handleTitleChange}
                            id="jobTitle" // Added id for label association
                            className="rounded-xl" // ADDED ROUNDED CORNERS
                        />
                    </div>
                    <div>
                        <Textarea
                            placeholder="Job Description"
                            value={jobDescription}
                            onChange={handleDescriptionChange}
                            rows={15} // You can adjust rows as needed
                            id="jobDescription" // Added id for label association
                            className="rounded-xl" // ADDED ROUNDED CORNERS
                        />
                    </div>

                    <div className="flex items-center justify-between"> {/* Added container for toggle and label */}
                        <div className="flex items-center">
                            <Switch
                                checked={isLinkedInSearchEnabled}
                                onCheckedChange={setIsLinkedInSearchEnabled}
                            // className="rounded-md bg-gray-200 dark:bg-gray-700 border" // **MODIFIED SWITCH STYLING HERE**
                            />
                            <label htmlFor="linkedin-search" className="ml-2 text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                LinkedIn Search
                            </label>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Info className="text-black bg-transparent ml-4" style={{ width: "1rem" }} />
                                </TooltipTrigger>
                                <TooltipContent className="text-xs font-normal">
                                    <p>Enable LinkedIn Search (experimental). This feature is usage-capacity intensive.</p>
                                    <p>Use of this feature will limit the amount of usage available for other tools.</p>
                                </TooltipContent>
                            </Tooltip>
                        </div>
                    </div>


                    <div className="flex justify-end space-x-2">
                        <Button type="submit" disabled={loading} size="sm" className="rounded-xl">Submit Description</Button> {/* ADDED ROUNDED CORNERS */}
                        <Button type="button" variant="secondary" onClick={handleReset} disabled={loading} size="sm" className="rounded-xl">Clear</Button> {/* ADDED ROUNDED CORNERS */}
                    </div>
                </form>
            </div>
        </div>
    );


    return (
        <div className="space-y-4"> {/* Removed outer Card, using div with space-y-4 for content spacing */}
            <div className="flex justify-between items-center pb-2"> {/* Replaced CardHeader with div and flex layout */}
            </div>
            <div> {/* Replaced CardContent with div */}
                {loading && (
                    <div className="relative pb-2 mb-4"> {/* Added mb-4 for spacing below progress */}
                        <Progress value={40} className="w-full" />
                        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center text-sm">
                            Processing Job Description...
                        </div>
                    </div>
                )}

                {isDescriptionSubmitted ? renderForm() : renderResults()}
            </div>
        </div>
    );
};

export default JobDescriptionUpload;