import { getIdToken } from '../services/userDataService';

/**
 * Fetches the shared chat sessions for a given user.
 *
 * @async
 * @function fetchSharedChats
 * @param {object} userData - An object containing user data, including `user_data.user_id` and `user_data.org_id`.
 * @param {object} userData.user_data - An object containing user-specific data.
 * @param {string} userData.user_data.user_id - The ID of the user.
 * @param {string} userData.user_data.org_id - The ID of the user's organization.
 * @returns {Promise<object[]>} A promise that resolves with an array of shared chat session objects. Returns an empty array if there are no shared sessions or if an error occurs.
 * @throws {Error} Throws an error if there's an issue fetching the shared chats.
 */
export const fetchSharedChats = async (userData) => {
  try {
    console.log('userData', userData);
    const token = await getIdToken();
    const response = await fetch(process.env.REACT_APP_GET_SHARED_CHATS, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
      },
      body: JSON.stringify({
        userId: userData.user_data.user_id,
        orgId: userData.user_data.org_id
      })
    });
    const data = await response.json();
    console.log('data', data);
    return data.shared_sessions || [];
  } catch (error) {
    console.error('Error fetching shared chats:', error);
    return [];
  }
};
