import React, { useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../../components/ui/dialog"

import { Button } from "../../components/ui/button"
import { Label } from "../../components/ui/label"

/**
 * FilterModal is a React component that provides a modal dialog for applying filters to documents and data sources.
 *
 * @component
 * @param {object} props - The component props.
 * @param {boolean} props.open - Determines whether the modal is open or closed.
 * @param {function} props.onClose - Callback function to close the modal.
 * @param {string[]} props.tagList - List of available tags for filtering.
 * @param {object[]} props.documents - List of documents to filter.
 * @param {object[]} props.projects - List of projects to filter.
 * @param {object} props.currentFilters - The currently applied filters.
 * @param {string[]} props.currentFilters.tags - Currently selected tags.
 * @param {string[]} props.currentFilters.folders - Currently selected folders.
 * @param {string[]} props.currentFilters.files - Currently selected files.
 * @param {string[]} props.currentFilters.project - Currently selected projects.
 * @param {function} props.onApplyFilters - Callback function to apply the selected filters.
 * @returns {JSX.Element} The rendered FilterModal component.
 */
const FilterModal = ({
  open,
  onClose,
  tagList = [],
  documents = [],
  projects = [],
  currentFilters = {},
  onApplyFilters,
}) => {
  // local state to stage changes before applying
  const [localTags, setLocalTags] = useState(currentFilters.tags || [])
  const [localFolders, setLocalFolders] = useState(currentFilters.folders || [])
  const [localFiles, setLocalFiles] = useState(currentFilters.files || [])
  const [localProjects, setLocalProjects] = useState(currentFilters.project || [])

  /**
   * Toggles a value in a multi-select array.
   *
   * @function toggleValue
   * @param {any} value - The value to toggle.
   * @param {any[]} array - The array to toggle the value in.
   * @param {function} setArray - The state setter function for the array.
   * @returns {void}
   */
  const toggleValue = (value, array, setArray) => {
    if (array.includes(value)) {
      setArray(array.filter((item) => item !== value))
    } else {
      setArray([...array, value])
    }
  }

  /**
   * Clears all selected filters in the local state.
   *
   * @function handleClear
   * @returns {void}
   */
  const handleClear = () => {
    setLocalTags([])
    setLocalFolders([])
    setLocalFiles([])
    setLocalProjects([])
    onClose() // close the modal
  }

  /**
   * Applies the selected filters and closes the modal.
   *
   * @function handleApply
   * @returns {void}
   */
  const handleApply = () => {
    // Build a new filters object from the local states
    const newFilters = {
      ...currentFilters,
      tags: localTags,
      folders: localFolders,
      files: localFiles,
      project: localProjects,
    }
    onApplyFilters(newFilters)
    onClose() // close the modal
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-[800px] rounded-3xl">
        <DialogHeader>
          <DialogTitle>Filters</DialogTitle>
          <DialogDescription>
            Apply filters to define which documents or data sources to use.
          </DialogDescription>
        </DialogHeader>

        {/* TAGS multi-select */}
        <div className="mt-4 space-y-2">
          <Label className="font-semibold">Tags</Label>
          {tagList.map((tag) => (
            <div key={tag} className="flex items-center gap-2">
              <input
                type="checkbox"
                id={`tag-${tag}`}
                checked={localTags.includes(tag)}
                onChange={() => toggleValue(tag, localTags, setLocalTags)}
              />
              <Label htmlFor={`tag-${tag}`}>{tag}</Label>
            </div>
          ))}
        </div>

        {/* FOLDERS multi-select */}
        <div className="mt-6 space-y-2">
          <Label className="font-semibold">Folders</Label>
          {["Folder A", "Folder B", "Misc"].map((folderName) => (
            <div key={folderName} className="flex items-center gap-2">
              <input
                type="checkbox"
                id={`folder-${folderName}`}
                checked={localFolders.includes(folderName)}
                onChange={() =>
                  toggleValue(folderName, localFolders, setLocalFolders)
                }
              />
              <Label htmlFor={`folder-${folderName}`}>{folderName}</Label>
            </div>
          ))}
        </div>

        {/* FILES multi-select */}
        <div className="mt-6 space-y-2 max-h-36 overflow-y-auto">
          <Label className="font-semibold">Files</Label>
          {documents.map((doc) => (
            <div key={doc.document_uuid} className="flex items-center gap-2">
              <input
                type="checkbox"
                id={`doc-${doc.document_uuid}`}
                checked={localFiles.includes(doc.document_uuid)}
                onChange={() =>
                  toggleValue(doc.document_uuid, localFiles, setLocalFiles)
                }
              />
              <Label htmlFor={`doc-${doc.document_uuid}`}>
                {doc.document_name}
              </Label>
            </div>
          ))}
        </div>

        {/* PROJECTS multi-select */}
        <div className="mt-6 space-y-2 max-h-36 overflow-y-auto">
          <Label className="font-semibold">Projects</Label>
          {projects.map((p) => (
            <div key={p.name} className="flex items-center gap-2">
              <input
                type="checkbox"
                id={`proj-${p.name}`}
                checked={localProjects.includes(p.name)}
                onChange={() =>
                  toggleValue(p.jobId, localProjects, setLocalProjects)
                }
              />
              <Label htmlFor={`proj-${p.jobId}`}>{p.name}</Label>
            </div>
          ))}
        </div>

        <DialogFooter className="mt-6 flex justify-end">
          <Button className="rounded-3xl bg-indigo-700" onClick={onClose}>
            Cancel
          </Button>
          {/* Clear button calls handleClear */}
          <Button className="rounded-3xl bg-red-600" onClick={handleClear}>
            Clear
          </Button>
          <Button className="ml-2 rounded-3xl bg-blue-600" onClick={handleApply}>
            Apply
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default FilterModal
