import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Skeleton } from "../../components/ui/skeleton";
import JobOpeningUploadForm from './JobOpeningUploadForm';
import {
    Dialog,
    DialogTrigger,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
} from "../../components/ui/dialog"
import { ChevronDown } from "lucide-react";
import { cn } from "../../lib/utils";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion"


const SavedJobs = ({ userData }) => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const fetchJobs = async () => {
            setLoading(true);
            setError(null);
            console.log(userData.user_data.org_id);
            try {
                const apiUrl = `${process.env.REACT_APP_BASE_URL}/recruit-ai/jobs`; // **Replace with your actual API Gateway Invoke URL**
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ orgId: userData.user_data.org_id }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setJobs(data.jobs || []);
                console.log(data);
                setLoading(false);
            } catch (e) {
                setError(e.message);
                setLoading(false);
            }
        };

        fetchJobs();
    }, [userData?.user_data?.org_id]); // Corrected dependency array to handle potential undefined userData


    if (loading) {
        return (
            <div className="space-y-2">
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-full" />
            </div>
        );
    }

    if (error) {
        return <div className="text-red-500">Error loading jobs: {error}</div>;
    }


    return (
        <Card className="h-full">
            <CardHeader className="flex justify-between">
                <CardTitle className='flex justify-between items-center'>
                    <div className='text-2xl font-semibold'>
                        My Jobs
                    </div>
                    {/* **Dialog Trigger - Button to open Modal** */}
                    <Dialog>
                        <DialogTrigger asChild>
                            <Button size="lg" className='rounded-xl w-[140px]'>+ Add New Job</Button>
                        </DialogTrigger>
                        <DialogContent className="h-2/3 max-h-2/3 bg-trasnparent border-0">
                            <JobOpeningUploadForm userData={userData} insideModal={true} onClose={() => setIsModalOpen(false)}/>
                        </DialogContent>
                    </Dialog>
                </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4 max-h-[60vh] overflow-y-auto">
                {jobs.length > 0 ? (
                    <Accordion type="single" collapsible>
                        {jobs.map(job => (
                            <AccordionItem key={job.jobId} value={job.jobId}>
                                <AccordionTrigger>
                                    <div className="font-semibold">{job.jobTitle}</div>
                                </AccordionTrigger>
                                <AccordionContent className='max-h-[300px] overflow-y-auto'>
                                    <div className="grid gap-4">
                                        <div>
                                            <p className="text-sm font-semibold">Job Summary:</p>
                                            <p className="text-sm text-muted-foreground">{job.jobSummary || 'Not available'}</p>
                                        </div>
                                        <div>
                                            <p className="text-sm font-semibold">Job Field:</p>
                                            <p className="text-sm text-muted-foreground">{job.jobField || 'Not available'}</p>
                                        </div>
                                        <div>
                                            <p className="text-sm font-semibold">Education Requirements:</p>
                                            <p className="text-sm text-muted-foreground">{job.educationRequirements || 'Not specified'}</p>
                                        </div>
                                        <div>
                                            <p className="text-sm font-semibold">Experience Requirements:</p>
                                            <p className="text-sm text-muted-foreground">{job.experienceRequirements || 'Not specified'}</p>
                                        </div>
                                        <div>
                                            <p className="text-sm font-semibold">Mandatory Skills:</p>
                                            {job.mandatorySkills && job.mandatorySkills.length > 0 ? (
                                                <ul className="list-disc pl-5 text-sm text-muted-foreground">
                                                    {job.mandatorySkills.map((skill, index) => (
                                                        <li key={index}>{skill}</li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p className="text-sm text-muted-foreground">Not specified</p>
                                            )}
                                        </div>
                                        <div>
                                            <p className="text-sm font-semibold">Preferred Skills:</p>
                                            {job.preferredSkills && job.preferredSkills.length > 0 ? (
                                                <ul className="list-disc pl-5 text-sm text-muted-foreground">
                                                    {job.preferredSkills.map((skill, index) => (
                                                        <li key={index}>{skill}</li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <p className="text-sm text-muted-foreground">Not specified</p>
                                            )}
                                        </div>
                                    </div>
                                </AccordionContent>
                            </AccordionItem>
                        ))}
                    </Accordion>
                ) : (
                    <p className="text-muted-foreground">No jobs saved yet.</p>
                )}
            </CardContent>
        </Card>
    );
};

export default SavedJobs;