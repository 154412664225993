// src/components/confirmationModal.js

import React from 'react';
import './ConfirmationModal.css'; // Ensure this path is correct

/**
 * ConfirmationModal Component
 *
 * Provides a reusable modal for displaying a confirmation message and handling
 * user confirmation or cancellation. It typically renders a message and two
 * buttons: one for confirming and one for canceling.
 *
 * @param {object} props - The component's props.
 * @param {string} props.message - The message to display in the modal.
 * @param {function} props.onConfirm - Callback function called when the user confirms the action.
 * @param {function} props.onCancel - Callback function called when the user cancels the action.
 * @returns {JSX.Element} The ConfirmationModal JSX element.
 */
const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="confirmation-modal-overlay" role="dialog" aria-modal="true">
      <div className="confirmation-modal">
        <p>{message}</p>
        <div className="confirmation-modal-buttons">
          <button className="confirm-button" onClick={onConfirm}>
            Yes
          </button>
          <button className="cancel-button" onClick={onCancel}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
