import React from 'react';
import './ProfileModal.css'; // We'll define some basic modal styling

/**
 * ProfileModal Component
 *
 * Displays user profile information in a modal. This component renders user
 * details such as username, name, permissions, job title, email, and organization IDs.
 *
 * @param {object} props - The component's props.
 * @param {object} props.userData - User data object, containing personal details.
 * @param {function} props.onClose - Callback function to close the modal.
 * @returns {JSX.Element} The ProfileModal JSX element.
 */
const ProfileModal = ({ userData, onClose }) => {
return (
    <div className="profile-modal-overlay">
        <div className="profile-modal">
            <h2>Profile</h2>
            <div className="profile-modal-content">
                <label>Username</label>
                <input type="text" value={userData.user_data.username} contentEditable={false} />

                <label>Name</label>
                <input type="text" value={userData.user_data.name} contentEditable={false} />

                <label>Permissions</label>
                {userData.userGroups.map(g => (
                    <div key={g}>
                        <label htmlFor={g} style={{ fontSize: 'small' }}>{g}</label>
                    </div>
                ))}

                <label>Job Title</label>
                <input type="text" style={{ fontSize: 'small' }} />

                <label>Email</label>
                <input type="email" value={userData.user_data.email} contentEditable={false} style={{ fontSize: 'small' }} />

                <label>Address</label>
                <input type="text" style={{ fontSize: 'small' }} />

                <label>User Details</label>
                <textarea rows="4" style={{ fontSize: 'small' }} />

                <p style={{ fontSize: 'small' }}>Organization Id: {userData.user_data.org_id}</p>
                <p style={{ fontSize: 'small' }}>User Id: {userData.user_data.user_id}</p>
            </div>
            <div className="profile-modal-actions">
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    </div>
);
};

export default ProfileModal;
