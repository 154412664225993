/**
 * Processes raw message data into a structured format for display.
 *
 * @function processMessages
 * @param {object[]} data - The raw message data fetched from the API. Each object should have `timestamp`, `question`, and `response` properties.
 * @returns {object[]} An array of message objects, each with `id`, `text`, and `sender` properties, formatted for display.
 */
function processMessages(data) {
  // Assuming data is the array of dictionaries you fetched
  return data.map((item) => [
    { id: `q-${item.timestamp}`, text: item.question, sender: 'me' },
    { id: `r-${item.timestamp}`, text: item.response, sender: 'bot' },
  ]).flat(); // Flattens the array of arrays into a single array
}

/**
* Fetches and processes messages for a given session ID.
*
* @async
* @function fetchMessagesForSession
* @param {string} sessionId - The ID of the session to fetch messages for.
* @returns {Promise<object[]|undefined>} A promise that resolves with an array of processed message objects if successful.
* The array is formatted for display, with each object having `id`, `text`, and `sender` properties.
* Returns `undefined` if there is an error fetching or processing the messages.
* @throws {Error} Throws an error if the network response is not ok.
*/
const fetchMessagesForSession = async (sessionId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/fetchMessages?sessionId=${encodeURIComponent(sessionId)}`);
    if (!response.ok) {
      throw new Error('Network response was not ok.');
    }
    const data = await response.json();
    const processedMessages = processMessages(data['0']['messages']);
    console.log(data);
    return processedMessages; // Assuming the API returns a JSON object with a messages array
  } catch (error) {
    console.error('There was an error fetching the messages:', error);
    console.log('Production!!!');
    return undefined;
  }
};

export default fetchMessagesForSession;
