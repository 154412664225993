// src/context/DocumentContext.js

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { getIdToken } from '../services/userDataService';
import { useNotification } from '../components/useNotification';

/**
 * DocumentContext
 *
 * Provides a context for managing document and folder data, their loading state,
 * and error handling within the application. It includes functions to fetch,
 * update, and navigate through the file system structure.
 *
 * @type {React.Context<object>}
 */
export const DocumentContext = createContext();

/**
 * DocumentProvider Component
 *
 * Provides the DocumentContext to its children.
 * Manages the loading, error, and document / folder states
 * It contains functions to fetch, update, and navigate folders.
 *
 * @param {object} props - The component's props.
 * @param {React.ReactNode} props.children - The child components to which the context will be provided.
 * @param {object} props.userData - User data object.
 * @returns {JSX.Element} The DocumentContext.Provider component with the state and functions.
 */
export const DocumentProvider = ({ children, userData }) => {
    /**
     * @type {[Array<object>, function]} documents - An array of document objects from the API.
     */
  const [documents, setDocuments] = useState([]);
    /**
     * @type {[Array<object>, function]} folders - An array of folder objects from the API.
     */
  const [folders, setFolders] = useState([]);
    /**
     * @type {[string|null, function]} currentFolder - The ID of the currently selected folder, or null for the root folder.
     */
  const [currentFolder, setCurrentFolder] = useState(null);
     /**
     * @type {[Array<object>, function]} breadcrumbs - An array of objects, representing navigation path (breadcrumbs).
     */
  const [breadcrumbs, setBreadcrumbs] = useState([{ id: null, name: 'Home' }]);
    /**
     * @type {[boolean, function]} isLoading - Boolean that represents loading state for documents and folders.
     */
  const [isLoading, setIsLoading] = useState(true);
    /**
     * @type {[string|null, function]} error - Represents an error message in case of API failure.
     */
  const [error, setError] = useState(null);

  const { addNotification } = useNotification();

    /**
     * Fetches documents and folders from the API based on the current folder.
     *
     * @param {string} [folderId=null] - The ID of the folder to fetch data for, null for the root.
     * @param {string} [folderName=''] - The name of the folder.
     */
  const fetchDocumentsAndFolders = async (folderId = null, folderName = '') => {
    setIsLoading(true);
    try {
      const token = await getIdToken();
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/fileExplorerRetrieval`, {
        userId: userData.user_data.user_id,
        folderId: folderId,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });

      const data = response.data;
      console.log('Fetched data:', data, 'folderId:', folderId, 'folderName:', folderName);
      setDocuments(data.documents || []);
      setFolders(data.folders || []);

      let isRoot = !folderId;

      if (!isRoot) {
        folderName = folderName || 'Unknown Folder';
      }

      updateBreadcrumbs(folderId, isRoot, folderName);
    } catch (err) {
      console.error(err);
      addNotification('Failed to load documents and folders', 'error');
      setError('Failed to load documents and folders');
    } finally {
      setIsLoading(false);
    }
  };

    /**
   * Updates breadcrumbs based on the current folder for easier navigation.
   *
   * @param {string} folderId - The ID of the folder.
   * @param {boolean} isRoot - Boolean that represents whether or not the folder is a root folder.
   * @param {string} [folderName=''] - The name of the folder
   */
  const updateBreadcrumbs = (folderId, isRoot, folderName = '') => {
    if (isRoot) {
      // Reset to only "Home"
      setBreadcrumbs([{ id: null, name: 'Home' }]);
    } else {
      const newCrumb = { id: folderId, name: folderName };
      setBreadcrumbs(prev => {
        const existingIndex = prev.findIndex(crumb => crumb.id === folderId);
        if (existingIndex !== -1) {
          // Truncate breadcrumbs up to the existing folder
          return prev.slice(0, existingIndex + 1);
        } else {
          // Add the new folder to breadcrumbs
          return [...prev, newCrumb];
        }
      });
    }
  };

    /**
     * Handles navigation to a specific folder by setting the current folder and fetching data
     * for the folder using the fetchDocumentsAndFolders.
     *
     * @param {string} folderId - The ID of the folder to navigate to.
     * @param {string} folderName - The name of the folder to navigate to.
     */
  const navigateToFolder = (folderId, folderName) => {
    console.log('Navigating to folder:', folderId, folderName);
    setCurrentFolder(folderId, folderName);
    fetchDocumentsAndFolders(folderId, folderName);
  };


    /**
     * Adds or updates a document or folder in the context state.
     * If document_name is set to null, it deletes it instead.
     * If folder name is set to null, it deletes it instead.
     *
     * @param {object} doc - The document or folder object that needs to be added or updated.
     */
const addOrUpdateDocument = (doc) => {
    if (doc.document_uuid) {
      // Handle documents
      if (doc.document_name === null) {
        // Delete document
        setDocuments((prevDocs) => prevDocs.filter((d) => d.document_uuid !== doc.document_uuid));
      } else {
        // Add or update document
        setDocuments((prevDocs) => {
          const existingDocIndex = prevDocs.findIndex((d) => d.document_uuid === doc.document_uuid);
          if (existingDocIndex !== -1) {
            const updatedDocs = [...prevDocs];
            updatedDocs[existingDocIndex] = { ...updatedDocs[existingDocIndex], ...doc };
            return updatedDocs;
          } else {
            return [...prevDocs, doc];
          }
        });
      }
    } else if (doc.id) {
      // Handle folders similarly if needed
      if (doc.name === null) {
        // Delete folder
        setFolders((prevFolders) => prevFolders.filter((f) => f.id !== doc.id));
      } else {
        // Add or update folder
        setFolders((prevFolders) => {
          const existingFolderIndex = prevFolders.findIndex((f) => f.id === doc.id);
          if (existingFolderIndex !== -1) {
            const updatedFolders = [...prevFolders];
            updatedFolders[existingFolderIndex] = { ...updatedFolders[existingFolderIndex], ...doc };
            return updatedFolders;
          } else {
            return [...prevFolders, doc];
          }
        });
      }
    }
  };
  

    /**
     * Updates the status of a specific document by using its job_id.
     *
     * @param {string} job_id - The ID of the job of the document that is going to be updated.
     * @param {string} status - The status of the document to set.
     * @param {string} [documentType='unknown'] - The document type.
     */
  const updateDocumentStatus = (job_id, status, documentType = 'unknown') => {
    setDocuments(prevDocs => {
      const docIndex = prevDocs.findIndex(doc => doc.job_id === job_id);
      if (docIndex !== -1) {
        const updatedDoc = { ...prevDocs[docIndex], status, document_type: documentType };
        const updatedDocs = [...prevDocs];
        updatedDocs[docIndex] = updatedDoc;
        console.log(`Updated document ${job_id} status to ${status} and type to ${documentType}`);
        return updatedDocs;
      }
      return prevDocs;
    });
  };

  /**
     * Fetches initial data on component mount or when userData changes by calling the fetchDocumentsAndFolders function.
     */
  useEffect(() => {
    if (userData) {
      fetchDocumentsAndFolders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <DocumentContext.Provider
      value={{
        documents,
        folders,
        isLoading,
        error,
        navigateToFolder,
        addOrUpdateDocument,
        updateDocumentStatus,
        currentFolder,
        breadcrumbs,
      }}
    >
      {children}
    </DocumentContext.Provider>
  );
};
