import React, { useState, useEffect } from 'react';
import './candidates.css'; // You might not need this CSS file anymore
import { Link } from 'react-router-dom';
import { getIdToken } from '../../services/userDataService';
import { useNotification } from '../../components/useNotification';
import ResumeUpload from './resumeUpload'; // Import ResumeUpload Component

import { Card, CardHeader, CardTitle, CardContent } from "../../components/ui/card";
import { Skeleton } from "../../components/ui/skeleton";
import { Badge } from "../../components/ui/badge";
import { Button } from "../../components/ui/button"; // Import Button for Trigger
import {
    Dialog,
    DialogTrigger,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
} from "../../components/ui/dialog" // Import Shadcn Dialog Components


const Candidates = ({ userData }) => {
    const [candidateList, setCandidateList] = useState([]);
    const [loadingCandidates, setLoadingCandidates] = useState(true);
    const { addNotification } = useNotification();

    const getResumes = async () => {
        setLoadingCandidates(true);
        const token = await getIdToken();

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/fetchResumeCandidates?organizationId=${userData.user_data.org_id}`, {
                method: 'GET',
                headers: {
                    'Authorization': token,
                },
            });

            if (response.ok) {
                const result = await response.json();
                setCandidateList(result);
            } else {
                console.error('Failed to fetch candidates');
                addNotification('Failed to fetch candidates.', 'error');
            }
        } catch (error) {
            console.error('Error fetching candidates:', error);
            addNotification(`Error fetching candidates: ${error.message}`, 'error');
        } finally {
            setLoadingCandidates(false);
        }
    }

    useEffect(() => {
        getResumes();
    }, []);

    return (
        <div>
            <CardHeader className="pb-2">
                <CardTitle className='flex justify-between items-center'>
                    <div className='text-2xl font-semibold'>
                        Candidates
                    </div>
                     {/* **Dialog Trigger - Button to open Modal for Resume Upload** */}
                    <Dialog >
                        <DialogTrigger asChild>
                            <Button className='rounded-xl' size="lg">+ Upload Resume</Button>
                        </DialogTrigger>
                        <DialogContent className="sm:max-w-[700px] rounded-xl">
                            <DialogHeader>
                                <DialogTitle>Upload Resumes</DialogTitle>
                                <DialogDescription>
                                    Upload up to 5 resumes to the candidate pool. Drag and drop or select files.
                                </DialogDescription>
                            </DialogHeader>
                            <div className="grid gap-4 py-4">
                                {/* **Embed ResumeUpload Component Here** */}
                                <ResumeUpload userData={userData} insideModal={true} />
                            </div>
                            {/* No DialogFooter needed as ResumeUpload component has its own Submit/Clear buttons */}
                        </DialogContent>
                    </Dialog>
                </CardTitle>
            </CardHeader>
            <CardContent className="space-y-4 max-h-[60vh] overflow-y-auto"> 
                {loadingCandidates ? (
                    <div className="space-y-2">
                        <Skeleton className="h-4 w-full" />
                        <Skeleton className="h-4 w-full" />
                        <Skeleton className="h-4 w-full" />
                        <Skeleton className="h-4 w-full" />
                    </div>
                ) : candidateList.length > 0 ? (
                    <ul className="divide-y divide-gray-200">
                        {candidateList.map(candidate => (
                            <li key={candidate.applicantId} className="py-4 ">
                                <Link to={`/candidate/${candidate.applicantId}`} className="flex items-center space-x-4 hover:bg-gray-50 p-2 rounded-xl transition-colors duration-200 block">
                                    <div className="candidate-icon rounded-full bg-gray-200 flex items-center justify-center h-10 w-10">👤</div>
                                    <div className="candidate-info flex-1">
                                        <div className="font-semibold">{candidate.parsedData.name}</div>
                                        {candidate.parsedData.work_experience && candidate.parsedData.work_experience.length > 0 && (
                                            <div className="text-sm text-muted-foreground">
                                                Last Position: <Badge variant="secondary" className="ml-1 rounded-xl">{candidate.parsedData.work_experience[0].position}</Badge>
                                            </div>
                                        )}
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-muted-foreground">No candidates available yet.</p>
                )}
            </CardContent>
        </div>
    );
};

export default Candidates;