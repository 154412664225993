// FeedbackModal.js
import React, { useState } from 'react';
import './feedbackModal.css';

/**
 * FeedbackModal is a React component that provides a modal dialog for users to send feedback.
 *
 * @component
 * @param {object} props - The component props.
 * @param {function} props.onClose - Callback function to close the modal.
 * @returns {JSX.Element} The rendered FeedbackModal component.
 */
const FeedbackModal = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [isSending, setIsSending] = useState(false);

    /**
     * Handles sending the feedback.
     *
     * @async
     * @function handleSend
     * @returns {Promise<void>}
     */
    const handleSend = async () => {
        if (!email || !message || !subject) {
            alert('Please fill out all fields before sending feedback.');
            return;
        }

        setIsSending(true);

        try {
            // Example fetch call to your email endpoint
            // Adjust to your actual URL or AWS Lambda
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/feedback', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, message }),
            });

            if (!response.ok) {
                alert('Failed to send feedback. Please try again later.');
                return;
            }

            alert('Feedback sent successfully!');
            onClose(); // close modal
        } catch (error) {
            console.error('Error sending feedback:', error);
            alert('Error sending feedback.');
        } finally {
            setIsSending(false);
        }
    };

    return (
        <div className="feedback-modal-overlay">
            <div className="feedback-modal">
                <h2>Send Feedback</h2>
                <label>Your Email:</label>
                <input
                    type="email"
                    placeholder="your.email@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <label>Subject</label>
                <input
                    placeholder="Feedback Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                />

                <label>Your Message:</label>
                <textarea
                    placeholder="Let us know your thoughts..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />

                <div className="feedback-buttons">
                    <button onClick={handleSend} disabled={isSending}>
                        {isSending ? 'Sending...' : 'Send'}
                    </button>
                    <button onClick={onClose} disabled={isSending}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FeedbackModal;
