import React, { useRef, useState, useEffect } from 'react'
import { FaTimes, FaSearch } from 'react-icons/fa'
// Adjust the import path according to how your shadcn components are organized
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose
} from "../ui/dialog"
import './filtersModal.css'

/**
 * FiltersModal Component
 *
 * Provides a modal for filtering documents, projects, and tags.
 * Allows users to apply filters to the data set based on various criteria.
 * Includes options for document name search, project name search, selecting from
 * available tags, and adding custom instructions.
 *
 * @param {object} props - The component's props.
 * @param {boolean} props.isOpen - Controls whether the modal is open.
 * @param {function} props.onClose - Callback function to close the modal.
 * @param {object} props.filters - An object containing the initial filters state.
 * @param {function} props.onFilterChange - Callback function to apply the filters.
 * @param {Array<object>} props.documents - An array of document objects to filter.
 * @param {Array<object>} props.projects - An array of project objects to filter.
 * @param {Array<string>} props.tags - An array of available tags for filtering.
 * @returns {JSX.Element} The FiltersModal JSX element.
 */
export default function FiltersModal({
  isOpen,
  onClose,
  filters,
  onFilterChange,
  documents,
  projects,
  tags = []
}) {
    /**
    * @type {[object, function]} localFilters - Local copy of the filters state for managing changes before applying
    */
  const [localFilters, setLocalFilters] = useState(filters)
    /**
    * @type {[string, function]} documentSearch - Value of the document name search input
    */
  const [documentSearch, setDocumentSearch] = useState('')
    /**
    * @type {[string, function]} projectSearch - Value of the project name search input
    */
  const [projectSearch, setProjectSearch] = useState('')
    /**
    * @type {[Array<object>, function]} filteredDocuments - Array of filtered documents based on search.
    */
  const [filteredDocuments, setFilteredDocuments] = useState(documents)
    /**
    * @type {[Array<object>, function]} filteredProjects - Array of filtered projects based on search.
    */
  const [filteredProjects, setFilteredProjects] = useState(projects)


   /**
    * Keeps the localFilters state in sync with the parent's filter state.
    */
  useEffect(() => {
    setLocalFilters(filters)
  }, [filters])

    /**
    * Filters the documents array based on the documentSearch state.
    */
  useEffect(() => {
    setFilteredDocuments(
      documents.filter((doc) =>
        doc.document_name.toLowerCase().includes(documentSearch.toLowerCase())
      )
    )
  }, [documents, documentSearch])

    /**
     * Filters the projects array based on the projectSearch state.
     */
  useEffect(() => {
    setFilteredProjects(
      projects.filter((project) =>
        project.name.toLowerCase().includes(projectSearch.toLowerCase())
      )
    )
  }, [projects, projectSearch])


    /**
     * Updates the localFilters state with a new value for a specific filter type.
     *
     * @param {string} filterType - The type of filter being updated (e.g., 'customInstructions').
     * @param {any} value - The new value for the filter.
     */
  function handleFilterChange(filterType, value) {
    setLocalFilters((prev) => ({
      ...prev,
      [filterType]: value,
    }))
  }

    /**
     * Toggles the inclusion of a specific value within a filter type that can be an array.
     *
     * @param {string} filterType - The type of filter that is being updated (e.g., 'documents', 'tags').
     * @param {any} value - The value to be added/removed from the filter.
     */
  function handleCheckboxChange(filterType, value) {
    setLocalFilters((prev) => {
      const currentValues = prev[filterType] || []
      const updatedValues = currentValues.includes(value)
        ? currentValues.filter((item) => item !== value)
        : [...currentValues, value]
      return { ...prev, [filterType]: updatedValues }
    })
  }

  /**
     * Applies the current local filters, calls the onFilterChange callback, and closes the modal.
     */
  function applyFilters() {
    const finalFilters = {
      ...localFilters,
      documents: localFilters.documents,
      tags: localFilters.tags,
    }
    onFilterChange(finalFilters)
    onClose()
  }

    /**
     * Clears all filters and resets search inputs.
     */
  function clearFilters() {
    setLocalFilters({
      documents: [],
      project: [],
      tags: [],
      customInstructions: '',
    })
    setDocumentSearch('')
    setProjectSearch('')
  }

  // If you want the Dialog to unmount entirely when closed, 
  // you can conditionally render, or just let shadcn handle it.
  // But it's typical to do controlled usage of <Dialog>:
  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        // Whenever the dialog tries to close, call onClose.
        if (!open) onClose()
      }}
    >
      <DialogContent className="dialog-object h-[70%] z-50 rounded-3xl overflow-y-auto pr-8"> 
        {/* Adjust styling classes as needed */}
        <DialogHeader>
          <DialogTitle>Filter Options</DialogTitle>
          <DialogDescription>
            Select documents, projects, tags, or add custom instructions.
          </DialogDescription>
        </DialogHeader>

        {/* Close button - can also use <DialogClose> or a custom button */}
        <DialogClose asChild>
          <button
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
          >
            <FaTimes />
          </button>
        </DialogClose>

        {/* Documents Filter */}
        <div className="mb-0 border-b pb-4">
          <h3 className="text-lg font-semibold mb-2">Documents</h3>
          <div className="relative mb-2 flex items-center">
            <FaSearch className="absolute ml-2 text-gray-700" />
            <input
              type="text"
              placeholder="Search documents..."
              className="pl-8 pr-2 py-1 border border-gray-500 rounded-xl w-full"
              value={documentSearch}
              onChange={(e) => setDocumentSearch(e.target.value)}
            />
          </div>
          <div className="max-h-48 overflow-y-auto space-y-1">
            {filteredDocuments.map((doc) => (
              <label key={doc.document_id} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value={doc.document_name}
                  checked={localFilters.documents?.includes(doc.document_name)}
                  onChange={() => handleCheckboxChange('documents', doc.document_name)}
                />
                <span>{doc.document_name}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Projects Filter */}
        <div className="mb-2 border-b pb-4">
          <h3 className="text-lg font-semibold mt-0 mb-2">Projects</h3>
          <div className="relative mb-2 flex items-center">
            <FaSearch className="absolute ml-2 text-gray-700" />
            <input
              type="text"
              placeholder="Search projects..."
              className="pl-8 pr-2 py-1 border border-gray-500 rounded-xl w-full"
              value={projectSearch}
              onChange={(e) => setProjectSearch(e.target.value)}
            />
          </div>
          <div className="max-h-32 overflow-y-auto space-y-1">
            {filteredProjects.map((project) => (
              <label key={project.project_id} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value={project.project_id}
                  checked={localFilters.project?.includes(project.project_id)}
                  onChange={() => handleCheckboxChange('project', project.project_id)}
                />
                <span>{project.name}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Tags Filter */}
        <div className="mb-2 border-b pb-4">
          <h3 className="text-lg font-semibold mt-0 mb-2">Tags</h3>
          <div className="max-h-32 overflow-y-auto space-y-1">
            {tags.map((tag) => (
              <label key={tag} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  value={tag}
                  checked={localFilters.tags?.includes(tag)}
                  onChange={() => handleCheckboxChange('tags', tag)}
                />
                <span>{tag}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Custom Instructions */}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2 mt-0">Custom Instructions</h3>
          <textarea
            className="w-full h-36 border border-gray-400 rounded-3xl p-4"
            placeholder="Enter any custom instructions"
            value={localFilters.customInstructions || ''}
            onChange={(e) => handleFilterChange('customInstructions', e.target.value)}
          />
        </div>

        <DialogFooter>
          <button
            onClick={clearFilters}
            className="px-4 py-2 bg-transparent rounded-full border-2 border-red-600 text-red-600 hover:bg-red-600 hover:text-white transition-colors duration-300 mr-2"
          >
            Clear Filters
          </button>
          <button
            onClick={applyFilters}
            className="px-4 py-2 bg-transparent border-blue-600 border-2 text-blue-600 rounded-full hover:bg-blue-600 transition-colors duration-300 hover:text-white"
          >
            Apply Filters
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
