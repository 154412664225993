import { useState, useEffect, useRef } from 'react';

/**
 * useWebSocketListener Hook
 *
 * A custom React hook for managing WebSocket connections and listening
 * for incoming messages. It provides connection state, last message received
 * and manages the lifecycle of a websocket connection, including auto-reconnection.
 *
 * @param {string} url - The WebSocket URL to connect to.
 * @returns {object} An object containing connection status and the last received message.
 * @returns {boolean} isConnected - Boolean that represents connection state of the websocket.
 * @returns {any} lastMessage -  The last message received from the websocket.
 */
const useWebSocketListener = (url) => {
    /**
     * @type {[boolean, function]} isConnected - Tracks connection status to the websocket.
     */
  const [isConnected, setIsConnected] = useState(false);
    /**
     * @type {[any, function]} lastMessage - Stores the last message that was received from the websocket, and can be of any type.
     */
  const [lastMessage, setLastMessage] = useState(null);
   /**
    * @type {React.MutableRefObject<WebSocket>} socketRef - React ref that references the websocket object.
    */
  const socketRef = useRef(null);
   /**
    * @type {React.MutableRefObject<number>} reconnectAttemptsRef - React ref that references the number of reconnection attempts performed.
    */
  const reconnectAttemptsRef = useRef(0);
  /**
    * @type {number} maxReconnectAttempts - Represents the maximum number of connection attempts before giving up.
    */
  const maxReconnectAttempts = 5;
    /**
    * @type {number} reconnectInterval - Represents the amount of time in miliseconds to wait before trying to reconnect.
    */
  const reconnectInterval = 5000; // 5 seconds

  /**
   * Sets up the websocket connection on component mount, reconnects if disconnected and parses messages from the websocket.
   * The hook cleans up the connection on component unmount.
   */
  useEffect(() => {
    if (!url) return;

    const connectWebSocket = () => {
      socketRef.current = new WebSocket(url);

      socketRef.current.onopen = () => {
        setIsConnected(true);
        reconnectAttemptsRef.current = 0;
        console.log('WebSocket connected');
      };

      socketRef.current.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          setLastMessage(message);
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

      socketRef.current.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      socketRef.current.onclose = (event) => {
        setIsConnected(false);
        console.log('WebSocket disconnected:', event);
        if (reconnectAttemptsRef.current < maxReconnectAttempts) {
          reconnectAttemptsRef.current += 1;
          setTimeout(() => {
            console.log('Attempting to reconnect WebSocket...');
            connectWebSocket();
          }, reconnectInterval);
        } else {
          console.error('Max reconnection attempts reached');
        }
      };
    };

    connectWebSocket();

    // Cleanup function
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [url]);

  return { isConnected, lastMessage };
};

export default useWebSocketListener;
