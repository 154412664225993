import React, { useState } from 'react';
import './SettingsPage.css';

/**
 * SettingsPage Component
 *
 * Provides a UI for displaying user settings. This component includes sections
 * for account settings, notifications, and theme options. The component manages state for input
 * fields and displays those values in the UI.
 *
 * @param {object} props - The component's props.
 * @param {object} props.userData - The data for the current user.
 * @returns {JSX.Element} The SettingsPage JSX element.
 */
const SettingsPage = ({ userData }) => {
    /**
     * @type {[string, function]} name - State that represents the name of the user.
     */
  const [name, setName] = useState(userData.user_data.name);
    /**
     * @type {[string, function]} email - Represents the email address of the user.
     */
  const [email, setEmail] = useState(userData.user_data.email);
   /**
     * @type {[string, function]} username - Represents the username of the user.
     */
  const [username, setUsername] = useState(userData.user_data.username);
    /**
     * @type {[boolean, function]} enableEmails - Boolean that represents if email notifications are enabled.
     */
  const [enableEmails, setEnableEmails] = useState(true);
    /**
    * @type {[boolean, function]} enablePush - Represents if push notifications are enabled.
    */
  const [enablePush, setEnablePush] = useState(false);
    /**
     * @type {[string, function]} theme - String representing the currently selected theme, which can either be 'light' or 'dark'.
     */
  const [theme, setTheme] = useState('light'); // or 'dark'
  
    /**
     * Handles the submission of the settings form. Placeholder that displays an alert.
     *
     * @param {object} e - The submit event object.
     */
  const handleSaveSettings = (e) => {
    e.preventDefault();
    // In a real app, you’d send this data to your backend / update user settings
    alert('Settings saved! (Not really, just a placeholder)');
  };

  return (
    <div className="settings-container">
      <h2>Settings</h2>

      <form onSubmit={handleSaveSettings}>
        {/* Account Settings */}
        <section className="settings-section">
          <h3>Account Settings</h3>
          
          <div className="form-group">
            <label>Name</label>
            <input 
              type="text" 
              value={name} 
              disabled={true}
            />
          </div>
          
          
          <div className="form-group">
            <label>Email</label>
            <input 
              type="email" 
              value={email} 
              disabled={true}
            />
          </div>
        </section>
        
        {/* Notifications */}
        <section className="settings-section">
          <h3>Notifications</h3>
          
          <div className="toggle-group">
            <label>
              <input 
                type="checkbox" 
                checked={enableEmails} 
                onChange={e => setEnableEmails(e.target.checked)} 
              />
              Enable Email Notifications
            </label>
          </div>
        </section>
        
        {/* Theme */}
        <section className="settings-section">
          <h3>Theme</h3>
          <div className="theme-options">
            <label>
              <input
                type="radio"
                name="theme"
                value="light"
                checked={theme === 'light'}
                onChange={(e) => setTheme(e.target.value)}
              />
              Light
            </label>
          </div>
        </section>
        
        <button type="submit" className="save-button" disabled={true} style={{cursor: 'not-allowed'}}>
          Save Settings
        </button>
      </form>
    </div>
  );
};

export default SettingsPage;
