// src/functions/authService.js
import { getCurrentUser } from 'aws-amplify/auth';

/**
 * Retrieves the currently authenticated user's ID using AWS Amplify Auth.
 *
 * @async
 * @function currentAuthenticatedUser
 * @returns {Promise<string|undefined>} The currently authenticated user's ID if successful, or `undefined` if an error occurs.
 * @throws {Error} Will throw an error if there's an issue with `getCurrentUser` from Amplify.
 */
export async function currentAuthenticatedUser() {
  try {
    console.log('Getting current authenticated user');
    const { username, userId, signInDetails } = await getCurrentUser();
    console.log(`The username: ${username}`);
    console.log(`The userId: ${userId}`);
    console.log(`The signInDetails: ${signInDetails}`);

    return userId;
  } catch (err) {
    console.log(err);
    return undefined; // Explicitly return undefined in case of error
  }
}
