// bidAiContext.js
import React, { createContext, useState } from 'react';

/**
 * BidAiContext
 *
 * Provides a context for managing the refresh state for BidAI components.
 * This context includes a boolean value (`shouldRefresh`) and functions to trigger and clear it.
 * This context is designed to trigger a re-render of components that are dependent on BidAI data.
 *
 * @type {React.Context<object>}
 */
export const BidAiContext = createContext();

/**
 * BidAiProvider Component
 *
 * Provides the BidAiContext to its children, and manages the `shouldRefresh` state.
 *
 * @param {object} props - The component's props.
 * @param {React.ReactNode} props.children - The child components to which the context will be provided.
 * @returns {JSX.Element} The BidAiContext.Provider component with the state and functions.
 */
export const BidAiProvider = ({ children }) => {
    /**
     * @type {[boolean, function]} shouldRefresh - Represents whether a refresh should be triggered or not.
     */
    const [shouldRefresh, setShouldRefresh] = useState(false);

    /**
     * Triggers a refresh by toggling the `shouldRefresh` state.
     * This will cause components listening to the context to update.
     */
    const triggerRefresh = () => {
        setShouldRefresh(prev => !prev);
    };

    /**
     * Clears the refresh state by setting `shouldRefresh` to false.
     */
    const clearRefresh = () => {
        setShouldRefresh(false);
    };

    return (
        <BidAiContext.Provider value={{ shouldRefresh, triggerRefresh, clearRefresh }}>
            {children}
        </BidAiContext.Provider>
    );
};
