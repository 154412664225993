// src/components/documentPage/ChunksModal.js
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { X, RefreshCw } from 'lucide-react'; // Importing RefreshCw for Reindex icon
import LoadingSpinner from '../../components/assets/loadingSpinner';
import './ChunksModal.css';
import { useNotification } from '../../components/useNotification'; // Adjust the path if necessary
import axios from 'axios';
import { getIdToken } from '../../services/userDataService';
import { chunk } from 'lodash';

/**
 * ChunksModal Component
 *
 * Displays chunk data in a modal. This component displays data related to document chunks
 * in a tabular format, with an option to reindex each individual chunk.
 *
 * @param {object} props - The component's props.
 * @param {boolean} props.isOpen - Determines if the modal is visible.
 * @param {function} props.onClose - Function to close the modal.
 * @param {Array<object>} props.chunks - Array of chunk data.
 * @param {boolean} props.isLoading - Indicates if chunk data is being loaded.
  * @param {string} props.error - Error message if fetching fails.
  * @param {string} props.documentId - The ID of the document that the chunks belong to.
  * @param {object} props.userData - The data for current user.
 * @returns {JSX.Element|null} The ChunksModal JSX element, or null if the modal is not open.
 */
const ChunksModal = ({ isOpen, onClose, chunks, isLoading, error, documentId, userData }) => {
    const { addNotification } = useNotification();
      /**
     * Handles reindexing a specific chunk, and notifies the user of success or failure.
     *
     * @param {string} chunkId - The ID of the chunk that is going to be reindexed.
     */
    const handleReindexChunk = async (chunkId) => {
        if (!window.confirm(`Are you sure you want to reindex chunk ${chunkId}?`)) {
            return;
        }
        console.log("userData Database:", userData.vector_databases[0].database_id);
        console.log("userData Org:", userData.org_data.org_id);
        console.log("userData User:", userData.user_data.user_id);
        console.log(chunkId);
        console.log(documentId);
        try {
            const token = await getIdToken();
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/document/${documentId}/chunks/reindex`, // Replace with your API endpoint
                {
                    chunk_id: chunkId,
                    document_id: documentId,
                    user_id: userData.user_data.user_id,
                    database: userData.vector_databases[0].database_id,
                    orgId: userData.org_data.org_id,
                  },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },

                }
            );

            if (response.status === 200) {
                addNotification(`Chunk ${chunkId} reindexing initiated successfully.`, 'success');
                // Optionally, refresh the chunks data by re-fetching
                // You might need to pass a callback prop to trigger data refresh from the parent component
            } else {
                throw new Error('Failed to initiate reindexing.');
            }
        } catch (err) {
            console.error('Error reindexing chunk:', err);
            addNotification(`Failed to reindex chunk ${chunkId}.`, 'error');
        }
    };
    /**
    * Handles Escape key press when the modal is open and closes it.
    */
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        if (isOpen) {
            window.addEventListener('keydown', handleEsc);
        }
        return () => window.removeEventListener('keydown', handleEsc);
    }, [isOpen, onClose]);

    /**
     * Prevents scrolling on the body element while the modal is open.
     */
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    // Return null if the modal is not open
    if (!isOpen) return null;

    return (
        <div className="chunks-modal-overlay"
            onClick={onClose}
            role="dialog"
            aria-modal="true"
            aria-labelledby="chunks-modal-title"
        >
            <div className="chunks-modal" onClick={(e) => e.stopPropagation()}>
                {/* Modal Header */}
                <div className="chunks-modal-header">
                    <h2 id="chunks-modal-title">Chunk Data</h2>
                    <button className="chunks-modal-close-button" onClick={onClose} aria-label="Close Modal">
                        <X />
                    </button>
                </div>

                {/* Modal Body */}
                <div className="chunks-modal-body">
                    {isLoading ? (
                        <div className="chunks-modal-loader">
                            <LoadingSpinner />
                            <span>Loading chunks...</span>
                        </div>
                    ) : error ? (
                        <div className="chunks-modal-error">{error}</div>
                    ) : chunks.length === 0 ? (
                        <p>No chunk data available.</p>
                    ) : (
                        <table className="chunks-modal-table">
                            <thead>
                                <tr>
                                    <th>Chunk ID</th>
                                    <th>Created On</th>
                                    <th>Updated At</th>
                                    <th>Status</th>
                                    <th>Size</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {chunks.map((chunk) => (
                                    <tr key={chunk.chunk_id}>
                                        <td className="chunk-id-cell">{chunk.chunk_id}</td>
                                        <td>{new Date(chunk.created_at).toLocaleString()}</td>
                                        <td>{new Date(chunk.updated_at).toLocaleString()}</td>
                                        <td>
                                            {chunk.status === 'indexed' ? (
                                                <span className="status-complete">Indexed</span>
                                            ) : (
                                                <span className="status-incomplete">{chunk.status}</span>
                                            )}
                                        </td>
                                        <td>{chunk.size ? `${Math.round(chunk.size / 1024)} KB` : 'N/A'}</td>
                                        <td>
                                            {chunk.status !== 'indexed' && (
                                                <button
                                                    className="reindex-button"
                                                    onClick={() => handleReindexChunk(chunk.chunk_id)}
                                                    title={`Reindex Chunk ${chunk.chunk_id}`}
                                                    aria-label={`Reindex chunk ${chunk.chunk_id}`}
                                                >
                                                    <RefreshCw className="reindex-icon" />
                                                    Reindex
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

ChunksModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    chunks: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    documentId: PropTypes.string.isRequired,
    userData: PropTypes.object.isRequired,
};

ChunksModal.defaultProps = {
    error: null,
};

export default ChunksModal;
