import React, { useState, useRef } from 'react';
import './resumeUpload.css'; // You might remove this later
import { getIdToken } from '../../services/userDataService';
import { useNotification } from '../../components/useNotification';

// Shadcn UI Components
import { Card, CardHeader, CardTitle, CardContent } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input"; // Not directly used for file input, but might be useful later
import { Textarea } from "../../components/ui/textarea"; // Not directly used here
import { Skeleton } from "../../components/ui/skeleton"; // If you want to use skeleton for loading
import { Progress } from "../../components/ui/progress"; // Alternative loading indicator

const MAX_FILES = 5;

const ResumeUpload = ({ userData }) => {
    const [files, setFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const { addNotification } = useNotification();

    const addFiles = (newFiles) => {
        if (files.length + newFiles.length > MAX_FILES) {
            const allowedCount = MAX_FILES - files.length;
            if (allowedCount <= 0) {
                addNotification(`You can only upload up to ${MAX_FILES} files.`, 'error');
                return;
            }
            const subset = newFiles.slice(0, allowedCount);
            setFiles((prev) => [...prev, ...subset]);
            addNotification(`You can only upload up to ${MAX_FILES} files. Truncated extra files.`, 'info');
        } else {
            setFiles((prev) => [...prev, ...newFiles]);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const droppedFiles = Array.from(e.dataTransfer.files);
        const pdfs = droppedFiles.filter(f => f.type === 'application/pdf');
        addFiles(pdfs);
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const pdfs = selectedFiles.filter(f => f.type === 'application/pdf');
        addFiles(pdfs);
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleClear = () => {
        setFiles([]);
    };

    const handleSubmit = async () => {
        if (files.length === 0) {
            addNotification('No files to upload.', 'error');
            return;
        }

        setLoading(true);

        try {
            const token = await getIdToken();
            const fileData = await Promise.all(files.map(file => fileToBase64(file)));

            const response = await fetch(process.env.REACT_APP_BASE_URL + '/ingestResume', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                body: JSON.stringify({
                    pdf_data: fileData,
                    org_id: userData.user_data.org_id,
                    database: userData.vector_databases[0].database_id
                }),
            });

            if (!response.ok) {
                addNotification('Failed to Upload Resume(s)', 'error');
                return;
            }

            const result = await response.json();
            console.log('Upload result:', result);
            addNotification('Resume(s) uploaded successfully!', 'success');
            handleClear();
        } catch (err) {
            console.error('Failed to upload files:', err);
            addNotification(`Failed to upload resumes: ${err.message}`, 'error');
        } finally {
            setLoading(false);
        }
    };

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve({
                    name: file.name,
                    data: reader.result
                });
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    return (
        <div className="space-y-4"> {/* Removed outer Card, using div with space-y-4 for content spacing */}
            <div className="space-y-2"> {/* Replaced CardHeader with div and spacing */}
                <div className="text-lg font-semibold">Resume Upload</div> {/* Replaced CardTitle */}
                {/* You could add CardDescription here if needed, or just a <p> */}
            </div>
            <div> {/* Replaced CardContent with div */}
                {loading && (
                    <div className="relative pb-2"> {/* Relative wrapper for progress bar */}
                        <Progress value={66} className="w-full" /> {/* Full width progress bar */}
                        <div className="w-full h-full flex items-center justify-center"> {/* Centered text */}
                            Processing Resume(s)...
                        </div>
                    </div>
                )}
                <div className="mb-4 flex justify-end space-x-2">
                    <Button onClick={handleSubmit} disabled={loading} size="sm" className="rounded-xl">Submit</Button> {/* ADDED ROUNDED CORNERS */}
                    <Button onClick={handleClear} variant="secondary" disabled={loading} size="sm" className="rounded-xl">Clear</Button> {/* ADDED ROUNDED CORNERS */}
                </div>

                {files.length === 0 ? (
                    <div
                        className="border-2 border-dashed border-gray-400 rounded-xl p-6 text-center cursor-pointer bg-gray-50 hover:bg-gray-100 transition-colors duration-200 rounded-xl" // ADDED ROUNDED CORNERS
                        onDrop={handleDrop}
                        onDragOver={(e) => e.preventDefault()}
                        onClick={handleClick}
                    >
                        <p className="text-muted-foreground mb-2">Drag and drop files here or click to select</p>
                        <p className="text-sm text-gray-500">(Max {MAX_FILES} PDFs)</p>
                        <input
                            type="file"
                            accept=".pdf"
                            multiple
                            onChange={handleFileChange}
                            ref={fileInputRef}
                            className="hidden" // Hide the default file input
                        />
                    </div>
                ) : (
                    <div className="space-y-2">
                        <p className="text-sm text-muted-foreground">Selected Files:</p>
                        <ul className="border rounded-xl"> {/* ADDED ROUNDED CORNERS */}
                            {files.map((file, index) => (
                                <li key={index} className="px-4 py-2 border-b last:border-b-0 bg-white hover:bg-gray-50 transition-colors duration-150 flex items-center justify-between rounded-xl"> {/* ADDED ROUNDED CORNERS */}
                                    {file.name}
                                    {/* You could add a remove file button here if needed */}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ResumeUpload;