// src/context/ReportContext.js
import React, { createContext, useState, useCallback } from 'react';

/**
 * ReportContext
 *
 * Provides a context for managing the refresh state for report components.
 * This context includes a boolean value (`shouldRefresh`) and functions to trigger and clear it.
 * This context is designed to trigger a re-render of components that are dependent on report data.
 *
 * @type {React.Context<object>}
 */
export const ReportContext = createContext();

/**
 * ReportProvider Component
 *
 * Provides the ReportContext to its children, and manages the `shouldRefresh` state.
 *
 * @param {object} props - The component's props.
 * @param {React.ReactNode} props.children - The child components to which the context will be provided.
 * @returns {JSX.Element} The ReportContext.Provider component with the state and functions.
 */
export const ReportProvider = ({ children }) => {
    /**
      * @type {[boolean, function]} shouldRefresh - Represents whether a report refresh should be triggered or not.
      */
    const [shouldRefresh, setShouldRefresh] = useState(false);

    /**
     * Triggers a report refresh by toggling the `shouldRefresh` state.
     * This function will cause components listening to the context to update.
     */
    const triggerReportRefresh = () => {
        setShouldRefresh(prev => !prev);
    };

    /**
     * Clears the report refresh state by setting the `shouldRefresh` to false.
     */
    const clearReportRefresh = () => {
        setShouldRefresh(false);
    };


    return (
        <ReportContext.Provider value={{ shouldRefresh, triggerReportRefresh, clearReportRefresh }}>
            {children}
        </ReportContext.Provider>
    );
};
